import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariables } from '../global';
import { DataService } from './data.service';
import {catchError} from 'rxjs/operators';
import { handleApiErrors } from '../_utils/api.util';

@Injectable()
export class QueueService {
  private basicUrl = GlobalVariables.BASE_API_URL + '/api/v1/branches';

  constructor(private http: HttpClient, private dataService: DataService) {}

  getQueue(queueId: number | string) {
    const url =`${this.basicUrl}/${this.dataService.branchData()}/branch_queues/${queueId}`;
    return this.http.get<any>(url).pipe(catchError((err) => handleApiErrors(err)));
  }
}
