import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import { GlobalVariables } from '../global';
import { DataService } from './data.service';
import { handleApiErrors } from '../_utils/api.util';
import { EmployeeConsole } from '../_classes';

@Injectable()
export class EmployeeConsoleEndpointsService {
  private basicUrl = GlobalVariables.BASE_API_URL + '/api/v1/branches';
  constructor(private http: HttpClient, private dataService: DataService, private route: ActivatedRoute) {}

  getBranchData() {
    const url = ` ${this.basicUrl}/${this.route.snapshot.params['branchId']}`;
    return this.http.get(url).pipe(catchError((err) => handleApiErrors(err)));
  }

  private getBranchId() {
    return this.route.snapshot.params['branchId'] || this.dataService.branchData();
  }

  getConsoleData(counterId, branchQueueId) {
    const branchId = this.getBranchId();
    const included = 'include=serving_token_for_current_day';

    const url = `${this.basicUrl}/${branchId}/branch_queues/${branchQueueId}/counters/${counterId}?${included}`;
    return this.http.get(url).pipe(catchError((err) => handleApiErrors(err)));
  }

  getCounterRoofData(counterId: number, branchQueueId: number) {
    const branchId = this.getBranchId();
    const included = 'include=serving_token_for_current_day,branch_queue';

    const url = `${this.basicUrl}/${branchId}/branch_queues/${branchQueueId}/counters/${counterId}?${included}`;
    return this.http.get(url).pipe(catchError((err) => handleApiErrors(err)));
  }

  getNextToken(counter: EmployeeConsole) {
    const { id, branchQueueId } = counter;

    const branchId = this.getBranchId();

    const url = `${this.basicUrl}/${branchId}/branch_queues/${branchQueueId}/tokens/${id}/next`;
    return this.http.get<any>(url).pipe(catchError((err) => handleApiErrors(err)));
  }

  markServed(counter: EmployeeConsole, paramTokenId?: number, paramBranchQueueId?: number) {
    const { currentlyServing } = counter;

    const branchId = this.getBranchId();
    const branchQueueId = paramBranchQueueId || currentlyServing.branchQueueId;
    const tokenId = paramTokenId || currentlyServing.id;

    const url = `${this.basicUrl}/${branchId}/branch_queues/${branchQueueId}/tokens/${tokenId}/served`;
    return this.http.get<any>(url).pipe(catchError((err) => handleApiErrors(err)));
  }

  recallEvent(counter: EmployeeConsole) {
    const { currentlyServing } = counter;

    const branchId = this.getBranchId();
    const tokenId = currentlyServing.id;
    const branchQueueId = currentlyServing.branchQueueId;

    const url = `${this.basicUrl}/${branchId}/branch_queues/${branchQueueId}/tokens/${tokenId}/recall`;
    return this.http.get<any>(url).pipe(catchError((err) => handleApiErrors(err)));
  }

  noShowEvent(counter: EmployeeConsole, paramTokenId?: number, paramBranchQueueId?: number) {
    const { currentlyServing } = counter;

    const branchId = this.getBranchId();
    const branchQueueId = paramBranchQueueId || currentlyServing.branchQueueId;
    const tokenId = paramTokenId || currentlyServing.id;

    const url = `${this.basicUrl}/${branchId}/branch_queues/${branchQueueId}/tokens/${tokenId}/no_show`;
    return this.http.get<any>(url).pipe(catchError((err) => handleApiErrors(err)));
  }

  updateDisplayName(cnsl, branchQueueId, customName) {
    const branchId = this.getBranchId();

    let url = `${this.basicUrl}/${branchId}/branch_queues/${branchQueueId}/counters/${cnsl.id}`;

    const body = {
      counter: {
        custom_name2: customName,
      },
    };

    return this.http.put(url, body).pipe(catchError((err) => handleApiErrors(err)));
  }
}
