import { GlobalVariables } from "../global";
import { MoodQuestion } from "./mood-question";

export class FeedbackStand {
	public id;
	public label;
	public value;
	public descriptionText;
	public textAboveLogoColor;
	public sidebarBackgroundColor;
	public logo;
	public feedbackMoods: Array<MoodQuestion>;

	constructor() {
    this.feedbackMoods = new Array<MoodQuestion>();
  }

	setFeedbackStandData(data) {
		this.id = data.id;
		this.value = this.id;
		this.label = data.attributes.descriptionText;
		this.descriptionText = this.label;
		this.textAboveLogoColor = data.attributes.textAboveLogoColor;
		this.sidebarBackgroundColor = data.attributes.sidebarBackgroundColor;
		this.logo = data.attributes.logo;
		this.setQuestions(data.attributes);
	}

	setQuestions(data) {
		let questionData = data.questionPack.data;
		let questions = new Array<MoodQuestion>();
		if (questionData) {
			let attributes  = Object.keys(questionData.attributes).map(i => questionData.attributes[i]);
			for(var i = 0; i < attributes.length; i++){
				let moodQuestion = new MoodQuestion();
				moodQuestion.type = i;
				moodQuestion.setMoodName(data);
				moodQuestion.setMoodQuestionData(attributes[i]);
				questions.push(moodQuestion);
			}
		}
		else {
			for(var i = 0; i < 4; i++){
				let moodQuestion = new MoodQuestion();
				moodQuestion.type = i;
				moodQuestion.setMoodName(data);
				questions.push(moodQuestion);
			}
		}
		this.feedbackMoods = questions;
	}
}
