import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService, FeedbackStandEndpointsService, FeedbackStand, GlobalVariables, MoodEnum } from 'app/common';

declare var swal: any;

@Component({
  selector: 'feedback-stand',
  templateUrl: './feedback-stand.component.html',
  styleUrls: ['./feedback-stand.component.scss'],
  providers: [FeedbackStandEndpointsService],
})
export class FeedbackStandComponent implements OnInit {
  feedbackStandData;
  message;
  moodSelected;
  currentMood;
  moods;
  notIndifferent;
  notBad;
  notGood;
  notExcellent;
  questions;
  routeId;
  formData: any = {};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService,
    private feedbackStandEndpointsService: FeedbackStandEndpointsService,
    private zone: NgZone
  ) {
    this.feedbackStandData = new FeedbackStand();
  }

  ngOnInit() {
    this.routeParams();
    this.loadFeedbackStand();
    this.zone.run(() => {
      console.log('enabled page re-render');
    });
  }

  routeParams() {
    this.route.params.subscribe((params) => {
      this.routeId = +params['id']; // (+) converts string 'id' to a number
    });
  }

  loadFeedbackStand() {
    this.feedbackStandEndpointsService.getFeedbackStandData(this.routeId).subscribe(
      (data: any) => {
        this.feedbackStandData = new FeedbackStand();
        this.feedbackStandData.setFeedbackStandData(data.data);
        this.moods = this.feedbackStandData.feedbackMoods;
      },
      (error) => {
        if (error[0].status == '404') {
          this.message = GlobalVariables.ERROR_MESSAGE_404;
        } else {
          this.message = GlobalVariables.DEFAULT_ERROR_MESSAGE;
        }

        this.alertMessage(this.message, error);
      }
    );
  }

  alertMessage(message, error = null) {
    swal({
      text: message,
      type: 'warning',
      allowOutsideClick: false,
      showCancelButton: false,
      allowEscapeKey: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Refresh the page!',
    }).then(() => {
      if (error && error[0].status == '401') {
        this.dataService.clearData();
        this.router.navigate(['login']);
      } else {
        location.reload();
      }
    });
  }

  setDynamicClass(mood) {
    if (mood.type == MoodEnum['excellent']) {
      return 'row-excellent';
    } else if (mood.type == MoodEnum['good']) {
      return 'row-good';
    } else if (mood.type == MoodEnum['indifferent']) {
      return 'row-indifferent';
    } else if (mood.type == MoodEnum['bad']) {
      return 'row-bad';
    }
  }

  moodLogo(mood) {
    if (mood.type == MoodEnum['excellent']) {
      return '/assets/images/excellent_smiley.png';
    } else if (mood.type == MoodEnum['good']) {
      return '/assets/images/good_smiley.svg';
    } else if (mood.type == MoodEnum['indifferent']) {
      return '/assets/images/indifferent_smiley.png';
    } else if (mood.type == MoodEnum['bad']) {
      return '/assets/images/bad_smiley.png';
    }
  }

  setMoodRating() {
    switch (this.currentMood.type) {
      case 0: {
        return 4;
      }
      case 1: {
        return 3;
      }
      case 2: {
        return 2;
      }
      case 3: {
        return 1;
      }
    }
  }

  setFeedbackQuestions(moodType) {
    this.moodSelected = true;
    if (this.moodSelected && this.feedbackStandData.feedbackMoods[moodType].questions) {
      this.questions = this.feedbackStandData.feedbackMoods[moodType].questions;
      this.currentMood = this.feedbackStandData.feedbackMoods[moodType];
    } else {
      this.questions = [];
      this.currentMood = this.feedbackStandData.feedbackMoods[moodType];
    }
  }

  setFeedbackData(moodType) {
    this.currentMood = this.feedbackStandData.feedbackMoods[moodType];
    this.moodSelected = true;
    this.formData.rating = this.setMoodRating();
    this.formData.branch_id = this.dataService.branchData();
    this.feedbackStandEndpointsService.generateFeedback(this.formData).subscribe(
      (data: any) => {
        setTimeout(() => {
          this.moodSelected = false;
        }, 2500);
      },
      (error) => {
        swal({
          text: this.errorMessage(error),
          type: 'warning',
          allowOutsideClick: false,
          showCancelButton: false,
          allowEscapeKey: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Refresh the page!',
        }).then(() => {
          if (error && error[0].status == '401') {
            this.dataService.clearData();
            this.router.navigate(['login']);
          } else {
            location.reload();
          }
        });
      }
    );
  }

  errorMessage(error) {
    let message;

    if (error[0] && error[0].status == '404') {
      message = GlobalVariables.ERROR_MESSAGE_404;
    } else if (error[0] && error[0].message) {
      message = error[0].message;
    } else {
      message = GlobalVariables.DEFAULT_ERROR_MESSAGE;
    }

    return message;
  }
}
