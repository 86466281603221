import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { GlobalVariables } from '../global';
import { DataService } from './data.service';
import { Howl } from 'howler';
import { Observable, concat, forkJoin, of } from 'rxjs';

export type Locale = 'nb-NO' | 'sv-SE' | 'en-US';

export const DEFAULT_SOUND_EFFECTS = {
  CALL_NEXT: GlobalVariables.CALL_NEXT_SOUND_URL,
  NEW_TOKEN: GlobalVariables.NEW_TOKEN_SOUND_URL,
  PRINTER_CALL_NEXT: GlobalVariables.CALL_NEXT_SOUND_URL,
  PRINTER_NEW_TOKEN: GlobalVariables.NEW_TOKEN_SOUND_URL,
};

export interface BranchSoundEffects {
  next_pending_token_sound: string;
  new_token_created_sound: string;
  printer_next_pending_token_sound: string;
  printer_next_token_created_sound: string;
}

export interface BranchSounds {
  data: {
    attributes: {
      audioAttachments: {
        tokenNumber: string;
        attachment: string;
      }[];
    };
  };
}

export interface BranchDetails {
  attachmentType: string;
  backgroundColor: string;
  companyName: string;
  fontColor: string;
  fontFamily: string;
  happy: number;
  neutral: number;
  logo: string;
  logoOriginal: string;
  name: string;
  ticketAttachment: string;
  ticketText: string;
  id: string;
  locale: Locale;
  playVoiceReads: boolean;
}

@Injectable()
export class BranchEndpointService {
  private basicUrl = GlobalVariables.BASE_API_URL + '/api/v1/branches';
  public sounds = {};
  public playVoiceReads = false;
  public details: BranchDetails;
  public soundEffects = {
    callNext: DEFAULT_SOUND_EFFECTS.CALL_NEXT,
    newToken: DEFAULT_SOUND_EFFECTS.NEW_TOKEN,
    printerCallNext: DEFAULT_SOUND_EFFECTS.CALL_NEXT,
    printerNewToken: DEFAULT_SOUND_EFFECTS.NEW_TOKEN,
  };

  constructor(private http: HttpClient, private dataService: DataService, private route: ActivatedRoute) {}

  init(id?: number | string) {
    this.getBranchDetails(id || this.dataService.branchData());
    this.getBranchSound();
  }

  getBranchDetails(id: number | string, { success = null, error = null } = {}) {
    if (!id) return;
    const url = `${this.basicUrl}/${id}/details`;

    this.http.get(url).subscribe(
      (res: any) => {
        const { data } = res;
        this.details = {
          ...data.attributes,
          id: data.id,
        };
        this.playVoiceReads = data.attributes.allowLanguage;
        success && success();
      },
      (err) => error && error(err)
    );
  }

  getBranchSound({ success, error } = { success: null, error: null }) {
    const branchId = this.dataService.branchData?.();
    if (!branchId) return;
    if (Object.keys(this.sounds).length > 0) return;

    const tokenSoundsUrl = `${this.basicUrl}/${branchId}/sound`;
    const soundEffectsUrl = `${this.basicUrl}/${branchId}/sound_effects`;

    forkJoin({
      tokenSounds: this.http.get<BranchSounds | 'error'>(tokenSoundsUrl).pipe(catchError((e) => of('error'))),
      soundEffects: this.http
        .get<BranchSoundEffects | 'error'>(soundEffectsUrl)
        .pipe(catchError((e) => of('error'))),
    }).subscribe(
      ({ tokenSounds, soundEffects }) => {
        if (tokenSounds !== 'error') {
          const { data } = tokenSounds as BranchSounds;
          const { audioAttachments } = data.attributes;

          this.sounds = {};
          audioAttachments.forEach((it) => (this.sounds[it.tokenNumber] = it.attachment));
        }

        if (soundEffects !== 'error') {
          const {
            next_pending_token_sound,
            new_token_created_sound,
            printer_next_pending_token_sound,
            printer_next_token_created_sound: printer_new_token_created_sound,
          } = soundEffects as BranchSoundEffects;

          this.soundEffects = {
            callNext: next_pending_token_sound || DEFAULT_SOUND_EFFECTS.CALL_NEXT,
            newToken: new_token_created_sound || DEFAULT_SOUND_EFFECTS.NEW_TOKEN,
            printerCallNext: printer_next_pending_token_sound || DEFAULT_SOUND_EFFECTS.CALL_NEXT,
            printerNewToken: printer_new_token_created_sound || DEFAULT_SOUND_EFFECTS.NEW_TOKEN,
          };
        }

        success && success();
      },
      (err) => {
        console.error(err);
        error && error(err);
      }
    );
  }

  playTokenEventSound(soundFile?, tokenNumber?: string | number) {
    const tokenReadSound = this.sounds[tokenNumber];

    let soundToPlay = this.soundEffects.callNext;

    if (soundFile) soundToPlay = soundFile;
    else if (this.playVoiceReads && tokenReadSound) soundToPlay = tokenReadSound;

    let sound: Howl;

    console.log(`playing sound for ${tokenNumber}`);
    sound = new Howl({ src: [soundToPlay], autoplay: true });
    sound.play();
  }

  logToServer(logs: { [key: string]: string }) {
    const url = `${GlobalVariables.BASE_API_URL}/api/v1/log_event`;
    return this.http.post(url, logs).subscribe();
  }
}
