<div id="horizontal-monitor">
  <div class="banner-wrapper">
    <div *ngIf="viewType === 'horizontal'" class="monitor-image clear-margin-padding">
      <div class="monitor-horz-image-carousel-wrapper">
        <app-image-carousel *ngIf="!isVideoCampaign" [images]="monitorData.attachments"></app-image-carousel>
      </div>
      <div *ngIf="isVideoCampaign" id="horizontalMonitorVideo">
        <iframe [src]="iframeSrc" frameborder="0" allowfullscreen></iframe>
      </div>
    </div>
    <div
      *ngIf="monitorData.enableCounters && monitorData.itemType == 'counter'"
      class="monitor-queues clear-margin-padding"
      [ngClass]="{ 'no-advertisement': viewType === 'no_advertisement' }"
    >
      <ul class="side-bar" id="monitor-list" [ngClass]="{ 'v-center': counterLowCount }">
        <li
          class="monitor-data"
          *ngFor="let counter of counters"
          [@tokenFlashAnimation]="animationData[counter.id]?.status"
          [ngStyle]="{ background: monitorData.backgroundColor, 'border-bottom': '1px solid ' + monitorData.borderColor }"
        >
          <div class="table-cell">
            <span
              class="label-text"
              [ngStyle]="{
                color: monitorData.counterFontColor,
                'font-family': monitorData.counterFontFamily,
                'font-size': monitorData.counterFontSize + 'px'
              }"
              [style.fontFamily]="monitorData.counterFontFamily"
            >
              {{ truncateValue(counter.name, 12) }}
            </span>
            <span
              class="value-text"
              [ngStyle]="{
                color: monitorData.tokenFontColor,
                'font-family': monitorData.tokenFontFamily,
                'font-size': monitorData.tokenFontSize + 'px'
              }"
              [style.fontFamily]="monitorData.tokenFontFamily"
            >
              {{ counter.currentlyServing.tokenNumber || '000' }}
            </span>
          </div>
        </li>
      </ul>
    </div>
    <div
      *ngIf="monitorData.enableCounters && monitorData.itemType == 'branch_queue'"
      class="monitor-queues clear-margin-padding"
      [ngClass]="{ 'no-advertisement': viewType === 'no_advertisement' }"
    >
      <ul class="side-bar" id="monitor-list" [ngClass]="{ 'v-center': counterLowCount }">
        <li
          class="monitor-data"
          *ngFor="let queue of queues"
          [@tokenFlashAnimation]="animationData[queue.id]?.status"
          [ngStyle]="{ background: monitorData.backgroundColor, 'border-bottom': '1px solid ' + monitorData.borderColor }"
        >
          <div class="table-cell">
            <span
              class="label-text"
              [ngStyle]="{
                color: monitorData.counterFontColor,
                'font-family': monitorData.counterFontFamily,
                'font-size': monitorData.counterFontSize + 'px'
              }"
              [style.fontFamily]="monitorData.counterFontFamily"
            >
              {{ queue.name }}
            </span>
            <span
              class="value-text"
              [ngStyle]="{
                color: monitorData.tokenFontColor,
                'font-family': monitorData.tokenFontFamily,
                'font-size': monitorData.tokenFontSize + 'px'
              }"
              [style.fontFamily]="monitorData.tokenFontFamily"
            >
              {{ queue.currentToken.tokenNumber || '000' }}
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
