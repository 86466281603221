<div
  class="monitor-screen-container"
  [ngStyle]="{ background: tokenDisplayCustomization?.backgroundColor, color: tokenDisplayCustomization?.fontColor }"
  (click)="refreshPage()"
  [@tokenIntvertColorFlashAnimation]="{
    value: tokenAnimationState,
    params: { fontColor: tokenDisplayCustomization?.fontColor, bgColor: tokenDisplayCustomization?.backgroundColor }
  }"
  (@tokenIntvertColorFlashAnimation.done)="monitorItemAnimationDone()"
>
  <div
    class="monitor-screen-item-container"
    *ngIf="!!monitorData.id && tokenDisplayCustomization?.backgroundColor; else noToken"
  >
    <div
      class="item-name-container"
      [ngStyle]="{
        'font-family': tokenDisplayCustomization?.counterFontFamily,
        fontSize: tokenDisplayCustomization.counterFontSize + 'px'
      }"
    >
      {{ monitorData.name }}
    </div>
    <div
      class="token-number"
      [ngStyle]="{
        'font-family': tokenDisplayCustomization?.tokenFontFamily
      }"
    >
      {{ latestTokenNumber }}
    </div>
  </div>
  <ng-template #noToken>
    <div class="idle-container" [ngStyle]="{ background: tokenDisplayCustomization?.backgroundColor }">
      <img src="{{ branchService.details?.logoOriginal }}" alt="" />
    </div>
  </ng-template>
</div>
