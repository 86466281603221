import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MonitorComponent } from './monitor.component';
import { MonitorHorzComponent } from './views/monitor-horz/monitor-horz.component';
import { MonitorVrtComponent } from './views/monitor-vrt.component/monitor-vrt.component';
import { MonitorTokenDisplayComponent } from './views/monitor-token-display/monitor-token-display.component';

@NgModule({
  declarations: [MonitorComponent, MonitorHorzComponent, MonitorVrtComponent, MonitorTokenDisplayComponent],
  imports: [CommonModule],
})
export class MonitorModule {}
