<div class="redesign-console-bg">
  <div class="employe-cons-header">
    <span class="logo">
      <img src="{{ employee.logoPath }}" alt="LOGO" />
    </span>
    <span class="header-title">{{ truncateValue(employee.counterName, 35) }}</span>
    <span class="date-text"
      ><span class="time-text">{{ dateToday | date : 'MMMM dd, yyyy' }}</span>
      <span class="time-text">{{ dateToday | date : 'HH:mm' }}</span>
    </span>
  </div>
  <!-------------------Body-------------------->
  <div class="main-flex-container">
    <span *ngIf="!tokensLoaded" class="loader-v-center"><i class="fa fa-spinner fa-pulse fa-fw fa-5x"></i></span>
    <div class="flex-wrapper" *ngIf="tokensLoaded">
      <div class="queues-detail-list">
        <div class="queues-inner">
          <div>
            <div class="queue-detail-label">
              <span>Queue No</span>
              <span>Waiting Time</span>
              <span>Mood</span>
            </div>
          </div>

          <div class="queue-list-wrapper">
            <ng-container *ngFor="let token of tokens">
              <div class="queue-list">
                <span>
                  <span class="queue-no">{{ token.tokenNumber }}</span>
                </span>
                <span class="wait-time">{{ token.waitTime | secondsToMinuteSeconds }}</span>
                <span class="{{ tokenMood(token) }}-cls"></span>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="counter-wrapper-flex">
        <div class="console-top-tab">
          <div class="queue-tab">
            <img src="../assets/images/queue_icon.png" alt="Image missing" />
            <div class="console-tab-text">
              <span>
                <p>People in Queue</p>
              </span>
              <span *ngIf="abandonsLoading"><i class="fa fa-refresh fa-pulse fa-fw"></i></span>
              <span *ngIf="!abandonsLoading">{{ employee.peopleInQueue }}</span>
            </div>
          </div>

          <div class="abandonments-tab">
            <img src="../assets/images/cancel_icon.png" alt="Image missing" />
            <div class="console-tab-text">
              <span>Abandonments</span>
              <span *ngIf="abandonsLoading"><i class="fa fa-refresh fa-pulse fa-fw"></i></span>
              <span *ngIf="!abandonsLoading">{{ employee.abandonments }}</span>
            </div>
          </div>

          <div class="served-tab">
            <img src="../assets/images/served_icon.png" alt="Image missing" />
            <div class="console-tab-text">
              <span>People Served</span>
              <span>{{ employee.peopleServed }}</span>
            </div>
          </div>
        </div>

        <div class="slip-details">
          <div class="slip-detail-inner" *ngIf="employee?.currentlyServing">
            <div class="served-status">
              <span>{{ employee.currentlyServing.status === 'serving' ? 'Currently Serving' : 'Previously Served' }}</span>
              <span class="token-container">
                {{ employee.currentlyServing.tokenNumber || '000' }}
              </span>
              <span class="serve-time">
                {{ employee?.currentlyServing?.servingTime | secondsToMinuteSeconds : true | twentyFourToZero }}
              </span>
            </div>
            <div class="button-controls">
              <ng-container *ngIf="employee.currentlyServing.status === 'serving'; else pending">
                <div class="btn-section mr-vw">
                  <button class="noshow-button" (click)="noShowEvent(employee)">
                    <img src="../assets/images/cross_icon_white.svg" alt="No Show" />
                  </button>
                  <span>No Show</span>
                </div>
                <div class="btn-section mr-vw">
                  <button class="recall-button xl-btn" (click)="recallEvent(employee)">
                    <img src="../assets/images/recall_hover.svg" alt="Recall" />
                  </button>
                  <span>Recall</span>
                </div>
                <div class="btn-section">
                  <button class="served-button" (click)="markServedEvent(employee)">
                    <img src="../assets/images/served_hover.svg" alt="Served" />
                  </button>
                  <span>Served</span>
                </div>
              </ng-container>
              <ng-template #pending>
                <div class="btn-section">
                  <button class="next-button xl-btn" (click)="nextEvent(employee)" [disabled]="isNextDisabled">
                    <img src="../assets/images/next.png" alt="Next" />
                  </button>
                  <span>Next</span>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
