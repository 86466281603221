import { BranchQueue } from './branch-queue';
import { Token } from './token';
import {
  DEFAULT_BACKGROUND_COLOR,
  DEFAULT_COUNTER_FONT_FAMILY,
  DEFAULT_COUNTER_FONT_SIZE,
  DEFAULT_FONT_COLOR,
  DEFAULT_TOKEN_FONT_FAMILY,
  DEFAULT_TOKEN_FONT_SIZE,
} from '../constants';

export class Counter {
  public id;
  public name;
  public customName;
  public currentQueue;
  public currentlyServing: Token;
  public value;
  public label;
  public animationFlag;
  public tokenFontFamily;
  public counterFontFamily;
  public fontColor;
  public backgroundColor;
  public tokenFontSize;
  public counterFontSize;
  public sound;
  public branchQueueId?: string;
  public nextTokenSoundEnabled: boolean;

  constructor() {
    this.currentlyServing = new Token();
    this.animationFlag = false;
    this.tokenFontFamily = DEFAULT_TOKEN_FONT_FAMILY;
    this.counterFontFamily = DEFAULT_COUNTER_FONT_FAMILY;
    this.fontColor = DEFAULT_FONT_COLOR;
    this.backgroundColor = DEFAULT_BACKGROUND_COLOR;
    this.tokenFontSize = DEFAULT_TOKEN_FONT_SIZE;
    this.counterFontSize = DEFAULT_COUNTER_FONT_SIZE;
  }

  setCounterData(data) {
    if (!data) return;

    const { id, attributes } = data;

    this.id = id;
    this.name = attributes.name;
    this.customName = attributes.customName;
    this.value = this.id;
    this.label = this.name;
    this.sound = attributes.sound;
    this.branchQueueId = attributes.branchQueueId;
    this.nextTokenSoundEnabled = attributes.nextTokenSoundEnabled;

    this.setFontStyles(data);
    this.setFontSizes(data);

    if (attributes) this.setCurrentlyServing(attributes.currentTokenNumber);
    this.currentQueue = new BranchQueue();
  }

  setFontStyles(data) {
    this.tokenFontFamily = data.attributes.tokenFontFamily || DEFAULT_TOKEN_FONT_FAMILY;
    this.counterFontFamily = data.attributes.counterFontFamily || DEFAULT_COUNTER_FONT_FAMILY;
    this.fontColor = data.attributes.fontColor || DEFAULT_FONT_COLOR;
    this.backgroundColor = data.attributes.backgroundColor || DEFAULT_BACKGROUND_COLOR;
  }

  setFontSizes(data) {
    this.tokenFontSize = data.attributes.tokenFontSize || DEFAULT_TOKEN_FONT_SIZE;
    this.counterFontSize = data.attributes.counterFontSize || DEFAULT_COUNTER_FONT_SIZE;
  }

  setCurrentlyServing(currentTokenNumber) {
    if (!this.currentlyServing) this.currentlyServing = new Token();
    this.currentlyServing.setTokenSerializedData(currentTokenNumber);
  }

  setBranchQueue(data) {
    this.currentQueue.setBranchQueueData(data);
  }
}
