import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { HeaderService, DataService, BranchEndpointService, GlobalVariables } from '../common/index';
import { version } from '../../../package.json';

declare var swal: any;

@Component({
  selector: 'main-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  showLogout: boolean = false;
  loggedIn;
  qtimeAppPrinterUrl: string = GlobalVariables.QTIME_APP_PRINTER_URL;
  version: string = version;
  constructor(
    private router: Router,
    private headerService: HeaderService,
    private dataService: DataService,
    public branchEndpointService: BranchEndpointService
  ) {
    this.validateLogin();
  }

  validateLogin() {
    this.loggedIn = this.dataService.sessionData();

    if (this.loggedIn) {
      this.showLogout = true;
    }
  }

  ngOnInit() {
    this.headerService.showNavBarEmitter.subscribe((data) => {
      if (data !== null) {
        this.showLogout = data;
      }
    });
  }

  logoutSession() {
    swal({
      title: 'Are you sure?',
      text: 'You will be logged out of the system by pressing "yes".',
      allowOutsideClick: true,
      showCancelButton: true,
      allowEscapeKey: false,
      confirmButtonColor: '#009D4F',
      confirmButtonText: 'Yes',
      cancelButtonColor: '#E94B4B',
      cancelButtonText: 'Cancel',
    })
      .then(() => {
        this.router.navigate(['/logout']);
      })
      .catch((_) => {
        return;
      });
  }
}
