import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import { GlobalVariables } from '../global';
import { handleApiErrors } from '../_utils/api.util';
import { forkJoin } from 'rxjs';

export type RecentQueueView = 'classic' | 'rollup_animated';

export interface RecentQueueDataAttributes {
  name: string;
  logo: string;
  fontColor: string;
  backgroundColor: string;
  branchQueueName: string;
  fontFileUrl: string | null;
  servingTokenNumber: string | null;
  fontFamily: string;
  activeQueueId: string | null;
  sound: string | null;
  queueFontSize: string;
  tokenFontSize: string;
  branchQueueId: number;
  view: RecentQueueView;
}

export interface RecentQueueData {
  data: {
    id: string;
    type: string;
    attributes: RecentQueueDataAttributes;
  };
}

export interface CounterCustomizationDataAttributes {
  name: string;
  tokenFontFamily: string;
  counterFontFamily: string;
  fontColor: string;
  backgroundColor: string;
  counterFontSize: string;
  tokenFontSize: string;
  nextTokenSoundEnabled: boolean;
  ownerType: string;
  ownerId: number;
  createdAt: string;
  updatedAt: string;
  showIdleScreenAfterInactivity: boolean;
  inactivityTimer: number;
}

export interface CounterCustomizationData {
  data: {
    id: string;
    type: string;
    attributes: CounterCustomizationDataAttributes;
  };
}

@Injectable()
export class RecentQueueEndpointsService {
  private basicUrl = GlobalVariables.BASE_API_URL + '/api/v1/branches';
  constructor(private http: HttpClient, private route: ActivatedRoute) {}

  getRecentQueueData(id: string | number) {
    const branchId = this.route.snapshot.params['branchId'];
    const recentQueueUrl = `${this.basicUrl}/${branchId}/recent_queues/${id}`;
    const customazationUrl = `${this.basicUrl}/${branchId}/counter_customization`;

    return forkJoin({
      recentQueueData: this.http.get<RecentQueueData>(recentQueueUrl),
      customization: this.http.get<CounterCustomizationData>(customazationUrl),
    }).pipe(catchError((err) => handleApiErrors(err)));
  }
}
