import { Component, OnInit, AfterViewChecked, OnDestroy, NgZone } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';

import {
  DataService,
  MonitorEndpointsService,
  GlobalVariables,
  Monitor,
  MonitorAnimationData,
  BranchEndpointService,
  BranchQueue,
  CounterCustomizationDataAttributes,
} from '../common/index';
import { AuthButtonService } from '../auth-button/index';
import { NewTokenData, PusherService } from 'app/common/_services/pusher.service';
import { MonitorScreenRes, MonitorScreenViewType } from '../common/_types';
import { getErrorMessage } from '../common/_utils/api.util';
import { tokenFlashAnimation } from '../common/common.animation';

declare var swal: any;

@Component({
  selector: 'monitor',
  templateUrl: './monitor.component.html',
  providers: [MonitorEndpointsService],
  animations: [tokenFlashAnimation()],
})
export class MonitorComponent implements OnInit, OnDestroy, AfterViewChecked {
  monitorData: Monitor;
  counters;
  queues: BranchQueue[];
  public tokenDisplayCustomization: CounterCustomizationDataAttributes;
  routeId;
  loggedIn;
  actionCable;
  counterLowCount = false;
  animationData: { [key: string]: MonitorAnimationData } = {};
  sound;
  unSubscribeCable;
  viewType: MonitorScreenViewType;
  sliderId;
  mediaLoaded = false;
  oldAttachments;
  counterChanged = false; // Checks if a counters have been enabled/disabled
  iframeSrc;
  isVideoCampaign = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService,
    private authButtonService: AuthButtonService,
    private monitorEndpointsService: MonitorEndpointsService,
    private zone: NgZone,
    private sanitizer: DomSanitizer,
    private pusherService: PusherService,
    private branchService: BranchEndpointService
  ) {
    this.validateLogin();
    this.monitorData = new Monitor();
  }

  ngOnInit() {
    if (this.loggedIn) {
      this.routeParams();
      this.loadMonitors();
      this.initPusherSubscription();

      this.zone.run(() => {
        console.log('enabled page re-render');
      });
    }
  }

  ngOnDestroy() {
    this.monitorData = null;
    this.counters = null;
    this.queues = null;
    this.routeId = null;
    this.loggedIn = null;
    this.counterLowCount = null;
    this.animationData = null;
    this.viewType = null;
    this.sliderId = null;
    this.mediaLoaded = null;
    this.oldAttachments = null;
    this.counterChanged = null;
    this.iframeSrc = null;
  }

  ngAfterViewChecked() {
    if (this.mediaLoaded || !this.viewType) return;

    if (this.isVideoCampaign) this.setVideoData();
    else this.setSliderData();
  }

  initPusherSubscription() {
    const branchId = this.route.snapshot.params['branchId'];
    this.pusherService.connectTokenBroadcast(branchId, this.handleNewTokenData);
  }

  navigateBasedOnViewType() {
    if (!this.monitorData?.id) return;
    const { viewType, id } = this.monitorData;
    const { branchId } = this.route.snapshot.params;

    this.router.navigate([`/branch/${branchId}/monitor/${id}/${viewType}`], { replaceUrl: true });
  }

  handleNewTokenData = (payload: NewTokenData) => {
    if (!payload) return;
    const { itemType, playTokenFetchSound, playSoundOnAllBranchQueues } = this.monitorData;
    const { id, attributes } = payload.data;
    const { branchQueueId, counterId, status, tokenNumber } = attributes;

    // TODO clean up, especially branchQueueId typing
    if (status === 'pending' && playTokenFetchSound) {
      return this.playNewPendingTokenSound(payload);
    } else if (status === 'serving') {
      const isCounterItems = itemType === 'counter';
      const id = isCounterItems ? counterId : branchQueueId;

      const monitorItems = isCounterItems ? this.counters : this.queues;
      const monitorItem = monitorItems.find((it) => it.id == id);

      // set currently serving token to show on monitor
      if (monitorItem) {
        if (isCounterItems) {
          monitorItem.currentlyServing = { tokenNumber, id };
        } else {
          monitorItem.currentToken.tokenNumber = tokenNumber;
          monitorItem.currentToken.id = id;
        }

        this.onNewTokenServingHook();

        this.triggerMonitorItemAnimation(`${id}`);
        this.branchService.playTokenEventSound(null, tokenNumber);

        // play sound despite monitorItem not found
      } else if (playSoundOnAllBranchQueues) {
        this.branchService.playTokenEventSound(null, tokenNumber);
      }
    }
  };

  triggerMonitorItemAnimation(itemId: string) {
    this.animationData[itemId].status = true;
    console.log(this.animationData[itemId]);
    setTimeout(() => (this.animationData[itemId].status = false), 4000);
  }

  setVideoData() {
    let videoSelector = document.getElementById(`${this.monitorData.viewType}MonitorVideo`);
    if (videoSelector) {
      this.mediaLoaded = true;
      if (!this.oldAttachments || this.oldAttachments != this.monitorData.attachments || this.counterChanged) {
        this.counterChanged = false;
        this.loadVideoHtml();
      }
    }
  }

  setSliderData() {
    let sliderFrame = document.getElementById(`${this.monitorData.viewType}MonitorSliderFrame`);
    if (sliderFrame) {
      this.mediaLoaded = true;
      if (
        !this.oldAttachments ||
        !this.sameArrays(this.oldAttachments, this.monitorData.attachments) ||
        this.counterChanged
      ) {
        this.counterChanged = false;
      }
    }
  }

  validateLogin() {
    this.loggedIn = this.dataService.sessionData();
    if (!this.loggedIn) this.router.navigate(['/login']);
    this.authButtonService.login();
  }

  routeParams() {
    this.route.params.subscribe((params) => {
      this.routeId = +params['id']; // (+) converts string 'id' to a number
    });
  }

  loadAnimationData() {
    const monitorItems = this.monitorData.itemType === 'counter' ? this.counters : this.queues;
    this.animationData = monitorItems.reduce((acc, val) => {
      acc[val.id] = new MonitorAnimationData(val);
      return acc;
    }, {});
  }

  getTimeDifference(startDate) {
    if (!startDate) return 0;

    const endDate = new Date();
    return (endDate.getTime() - startDate.getTime()) / 1000;
  }

  loadMonitors() {
    this.monitorEndpointsService.getMonitorData(this.routeId).subscribe(
      (res: MonitorScreenRes) => {
        const { data, included } = res;

        this.monitorData = new Monitor(data, included);
        this.isVideoCampaign = this.monitorData.mediaType === 'video';
        this.viewType = this.monitorData.viewType;

        if (this.viewType === 'token_display') {
          this.loadTokenDisplayCustomization();
        }

        this.navigateBasedOnViewType();

        const { itemType, counters, queues } = this.monitorData;
        let items = itemType === 'counter' ? counters : queues;

        this.counters = counters;
        this.queues = queues;

        if (items?.length <= 4) this.counterLowCount = true;

        this.onMonitorDataLoadedHook();
        this.loadAnimationData();
      },
      (error) => {
        swal({
          text: getErrorMessage(error),
          type: 'warning',
          allowOutsideClick: false,
          showCancelButton: false,
          allowEscapeKey: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Refresh the page!',
        }).then(() => {
          if (error && error[0].status == '401') {
            this.dataService.clearData();
            this.router.navigate(['login']);
          } else {
            location.reload();
          }
        });
      }
    );
  }

  loadTokenDisplayCustomization() {
    this.monitorEndpointsService.getTokenDisplayCustomization().subscribe((res: any) => {
      this.tokenDisplayCustomization = res?.data?.attributes;
    });
  }

  sameArrays(arr1, arr2) {
    return arr1.length == arr2.length && arr1.every((v, i) => v === arr2[i]);
  }

  truncateValue(value, arg): string {
    if (!value) return '';

    const limit = parseInt(arg, 10);
    return value.length > limit ? value.substring(0, limit) : value;
  }

  loadVideoHtml() {
    let videoOptions = this.stringifyVideoParams();
    let url = `${this.monitorData.attachments}?${videoOptions}`;
    this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  stringifyVideoParams() {
    let str = '';
    for (let key in GlobalVariables.VIDEO_URL_OPTIONS) {
      str += key + '=' + GlobalVariables.VIDEO_URL_OPTIONS[key] + '&';
    }
    str += `playlist=${this.monitorData.mediaId}`;

    return str;
  }

  playNewPendingTokenSound(payload: NewTokenData) {
    const { branchQueueId, totalPeopleInQueue } = payload?.data?.attributes || {};
    const { itemType, playSoundOnAllBranchQueues, playSoundBehaviourType } = this.monitorData;
    const { soundEffects } = this.branchService;

    const isCounterItems = itemType === 'counter';
    const monitorItems = isCounterItems ? this.counters : this.queues;
    const monitorItem = monitorItems.find((it) => (isCounterItems ? it.branchQueueId : it.id) == branchQueueId);
    const isIdMatch = !!monitorItem || playSoundOnAllBranchQueues;

    let isCorrectBehaviour = false;
    if (playSoundBehaviourType === 'people_in_queue_from_0_to_1') isCorrectBehaviour = totalPeopleInQueue === 1;
    else if (playSoundBehaviourType === 'all_new_tokens') isCorrectBehaviour = true;

    if (isIdMatch && isCorrectBehaviour) {
      this.branchService.playTokenEventSound(soundEffects.newToken);
    }
  }

  refreshPage() {
    location.reload();
  }

  //hooks for children
  onMonitorDataLoadedHook() {}
  onNewTokenServingHook() {}
}
