import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { DataService } from 'app/common/_services/data.service';
import { BranchEndpointService } from 'app/common/_services/branch-endpoint.service';
import { GlobalVariables } from '../global';
import { handleApiErrors } from '../_utils/api.util';
import { GetSignInResponse } from './../_types/api.types';

@Injectable()
export class AuthEndpointsService {
  private authUrl = GlobalVariables.BASE_API_URL + '/api/v1/sessions';
  public isLoggedIn: boolean = !!this.dataService.sessionData();
  public redirectUrl?: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private dataService: DataService,
    private branchService: BranchEndpointService
  ) {}

  login(formData) {
    const loginUrl = this.authUrl;

    return this.http.post(loginUrl, { account: formData }).pipe(
      catchError((err) => {
        return handleApiErrors(err);
      }),
      map((response: GetSignInResponse) => {
        const { attributes, relationships } = response?.data || {};

        this.isLoggedIn = true;

        this.dataService.setSessionData(attributes.accessToken);
        this.dataService.setCompanyLogo(attributes.logo);
        this.dataService.setBranchData(relationships.branch.data.id);

        this.branchService.getBranchSound();
        this.branchService.getBranchDetails(this.dataService.branchData());

        if (this.redirectUrl) {
          this.router.navigate([this.redirectUrl]);
          this.redirectUrl = null;
          return;
        }

        return response;
      })
    );
  }

  logout() {
    const loginUrl = this.authUrl;
    this.isLoggedIn = false;

    return this.http.delete(loginUrl).pipe(catchError((err) => handleApiErrors(err)));
  }
}
