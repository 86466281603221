import { environment } from '../../environments/environment';

interface ReactNativeWebViewInterface {
  postMessage(message: string): void;
}

declare global {
  interface Window {
    ReactNativeWebView: ReactNativeWebViewInterface;
  }
}

export const GlobalVariables = Object.freeze({
  BASE_API_URL: environment.apiUrl,
  BASE_CABLE_URL: environment.apiCableUrl,
  PUSHER_CONFIG: environment.pusher,
  UNUTHENTICATED: 'Please Log in to system first',
  DEFAULT_ERROR_MESSAGE: 'An error has occurred. Please refresh the page',
  ERROR_MESSAGE_404: 'There was a problem in completing this request. Please try again',
  VIDEO_URL_OPTIONS: {
    loop: 1,
    controls: 0,
    rel: 0,
    showinfo: 0,
    modestbranding: 1,
    cc_load_policy: 0,
    iv_load_policy: 3,
    mute: 1,
    autoplay: 1,
  },
  CALL_NEXT_SOUND_URL: environment.soundUrls.nextToken,
  NEW_TOKEN_SOUND_URL: environment.soundUrls.newPendingToken,
  QTIME_APP_PRINTER_URL: environment.qtimeAppPrinterUrl,
  QTIME_QR_URL: environment.qtimeQrUrl,
});
