import { Token } from './token';
import { timer } from 'rxjs';

export class EmployeeConsole {
  public id;
  public queue;
  public logoPath;
  public branchName;
  public counterName;
  public peopleInQueue;
  public servingTime;
  public peopleServed;
  public previouslyServed;
  public abandonments;
  public currentlyServing: Token;
  public branchQueueId;
  public servingTimerSub; // This is just a subscriber variable. Meant to clear out timers
  public nickname: string;
  public isEditingNickname = false;

  constructor() {
    this.currentlyServing = new Token();
  }

  setConsoleData(data) {
    const { id, attributes, relationships } = data;

    this.id = id;
    this.queue = data;
    this.peopleInQueue = attributes.peopleInQueue;
    this.peopleServed = attributes.servedPeople;
    this.previouslyServed = attributes.previouslyServed;
    this.abandonments = attributes.abandonments;
    this.counterName = attributes.name;
    this.branchQueueId = relationships.branchQueue.data.id;
    this.nickname = attributes.customName2;

    this.setCurrentlyServing(data?.attributes?.currentTokenNumber);
  }

  setCurrentlyServing(currentTokenNumber) {
    if(!this.currentlyServing) this.currentlyServing = new Token();

    const isSameToken = currentTokenNumber?.id && this.currentlyServing?.id;
    const isRecall = isSameToken && currentTokenNumber?.status === 'serving';
    this.currentlyServing.setTokenSerializedData(currentTokenNumber, isRecall);
  }

  isServing(): boolean {
    return this.currentlyServing?.status === 'serving';
  }

  startServingTimer() {
    if (this.servingTimerSub) return;

    this.servingTimerSub = timer(0, 1000).subscribe(() => {
      if (this.currentlyServing) this.currentlyServing.servingTime += 1;
    });
  }

  cleanServingTimer() {
    this.servingTimerSub?.unsubscribe();
    this.servingTimerSub = null;
    this.currentlyServing.servingTime = 0;
  }
}
