import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

import { AuthEndpointsService } from './../_services/auth-endpoints.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthEndpointsService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let url: string = state.url;
    return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {
    if (this.authService.isLoggedIn) return true;

    this.authService.redirectUrl = url;
    this.router.navigate(['/login']);
    return false;
  }
}
