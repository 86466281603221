import { throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { debounceTime, catchError } from 'rxjs/operators';

import { GlobalVariables } from '../global';
import { DataService } from './data.service';
import { GetPrinterResponse, GetTokenResponse } from '../_types/api.types';

@Injectable()
export class PrinterEndpointsService {
  private basicUrl = GlobalVariables.BASE_API_URL + '/api/v1/branches';
  constructor(private http: HttpClient, private dataService: DataService, private route: ActivatedRoute) {}

  getPrinterData(branchId, printerId) {
    const headers = this.defaultHeaders();
    const options = { headers };

    const queryParams = '?include=branch_queues,branch_queues.serving_token_for_current_day,printer_customization';

    const url = `${this.basicUrl}/${branchId}/printers/${printerId}${queryParams}`;

    return this.http.get<GetPrinterResponse>(url, options).pipe(catchError(this.handleError));
  }

  generateQueueToken(printerQueue, branchQueueId, branchId) {
    const headers = this.defaultHeaders();
    const options = { headers };
    const isTicketLess = printerQueue?.printerType === 'ticket_less';

    const url = `${this.basicUrl}/${branchId}/branch_queues/${branchQueueId}/tokens`;

    return this.http
      .post<GetTokenResponse>(
        url,
        {
          branch_queue_id: branchQueueId,
          origin_type: isTicketLess ? 'ticket_less' : 'web_paper',
        },
        options
      )
      .pipe(debounceTime(500), catchError(this.handleError));
  }

  updatePrinterIpAddress(branchId, data) {
    const headers = this.defaultHeaders();
    const options = { headers };

    let url = this.basicUrl + '/' + branchId + '/printers/' + data.printer.id;

    return this.http
      .post(
        url,
        JSON.stringify({
          printer: { id: data.printer.id, ip_address: data.ipValue },
        }),
        options
      )
      .pipe(catchError(this.handleError));
  }

  private handleError(err: Response | any) {
    return observableThrowError(err.json().errors);
  }

  defaultHeaders() {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('ACCESS-TOKEN', this.dataService.sessionData());
    return headers;
  }
}
