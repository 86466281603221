import { Answer } from "./answer";

export class Question {
	public id;
	public questionText;
	public answers: Array<Answer>;

	setQuestionData(data){
		this.answers = new Array<Answer>();
		this.questionText = data.text;
		this.id = data.id;
		for(var j = 0; j < data.answers.length; j++){
			let answer = new Answer();
			answer.setAnswerData(data.answers[j])
			this.answers.push(answer);
		}
	}
}
