import { CounterCustomizationRes } from './../_types/customizations';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import { GlobalVariables } from '../global';
import { handleApiErrors } from '../_utils/api.util';

@Injectable()
export class MonitorEndpointsService {
  private basicUrl = GlobalVariables.BASE_API_URL + '/api/v1/branches';
  constructor(private http: HttpClient, private route: ActivatedRoute) {}

  getMonitorData(id) {
    const branchId = this.route.snapshot.params['branchId'];
    const url = `${this.basicUrl}/${branchId}/monitor_screens/${id}?include=counters,branch_queues`;
    return this.http.get(url).pipe(catchError((err) => handleApiErrors(err)));
  }

  updateMonitorScreen(id, { counters, branchQueues }: { counters: string[]; branchQueues: string[] }) {
    const branchId = this.route.snapshot.params['branchId'];
    const url = `${this.basicUrl}/${branchId}/monitor_screens/${id}?include=counters,branch_queues`;

    const isCounter = counters.length > 0;

    const payload = {
      item_type: isCounter ? 'counter' : 'branch_queue',
    };

    if (isCounter) {
      payload['monitor_screen_counters_attributes'] = counters.reduce(
        (acc, val, index) => Object.assign(acc, { [index]: { counter_id: val } }),
        {}
      );
    } else {
      payload['monitor_queues_attributes'] = branchQueues.reduce(
        (acc, val, index) => Object.assign(acc, { [index]: { branch_queue_id: val } }),
        {}
      );
    }

    return this.http.put(url, payload).pipe(catchError((err) => handleApiErrors(err)));
  }

  getTokenDisplayCustomization() {
    const { branchId } = this.route.snapshot.params;
    const url = `${this.basicUrl}/${branchId}/counter_customization`;
    return this.http.get<CounterCustomizationRes>(url);
  }
}
