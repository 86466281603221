import { Question } from "./question";

export class MoodQuestion {
  public type;
  public moodName;
  public questions: Array<Question>;

  constructor() {
    this.questions = [];
  }

  setMoodQuestionData(data) {
    if(data) {
      this.questions = new Array<Question>();
      for(var i = 0; i < data.length; i++){
        let question = new Question();
        question.setQuestionData(data[i]);
        this.questions.push(question);
      }
    }
  }

  setMoodName(data) {
    switch (this.type) {
      case 0: {
        this.moodName = data.excellentMoodValue;
        break;
      }
      case 1: {
        this.moodName = data.goodMoodValue;
        break;
      }
      case 2: {
        this.moodName = data.indifferentMoodValue;
        break;
      }
      case 3: {
        this.moodName = data.badMoodValue;
        break;
      }

    }
  }
}

export enum MoodEnum {
  excellent = 0,
  good = 1,
  indifferent = 2,
  bad = 3
}
