import { Locale } from './_services';

export interface TranslationKeys {
  welcomeTo: string;
  printingToken: string;
  yourQueueNumber: string;
  getTicketHere: string;
  otherServices: string;
  digitalTicketOnPhone: string;
  scanQrForDigitalTicket: string;
  cannotPrintPaperTicket: string;
  forPaperTicket: string;
  qrBased_savePaper: string;
  qrBased_useMobileCamera: string;
  qrBased_instructions: string;
  qrBased_instructionStep1: string;
  qrBased_instructionStep2: string;
  qrBased_instructionStep3: string;
  qrBased_instructionStep4: string;
}

type LanguageTranslation = {
  [key in Locale]: TranslationKeys;
};

export const COMMON_TRANSLATIONS: LanguageTranslation = {
  'sv-SE': {
    welcomeTo: 'Välkommen till',
    printingToken: 'Kölapp skrivs ut',
    yourQueueNumber: 'Ditt könummer',
    getTicketHere: 'Dra kölapp här',
    otherServices: 'Andra tjänster',
    digitalTicketOnPhone: 'Digital kölapp på mobiltelefon',
    scanQrForDigitalTicket:
      'Skanna QR-kod för digital kölapp. Du kan sedan följa kön på din mobiltelefon och fortsätta handla medan du väntar på din tur.',
    cannotPrintPaperTicket: 'Kan inte skriva ut papperskölapp, var god skanna QR-koden ovan.',
    forPaperTicket: 'För pappersbaserad kölapp:',
    qrBased_savePaper: 'SPARA VÄRLDEN FÖR PAPPER',
    qrBased_useMobileCamera: 'ANVÄND MOBILKAMERA FÖR ATT TA EN DIGITAL KÖLAPP',
    qrBased_instructions: 'INSTRUKTIONER',
    qrBased_instructionStep1: 'Öppna mobilkameran.',
    qrBased_instructionStep2: 'Håll mobilen så att QR-koden visas på mobilskärmen.',
    qrBased_instructionStep3: 'Tryck på anmälan som visas på mobilskärmen.',
    qrBased_instructionStep4: 'Välj den avdelning du vill besöka.',
  },
  'nb-NO': {
    welcomeTo: 'Velkommen til',
    printingToken: 'Kølapp skrives ut',
    yourQueueNumber: 'Ditt kønummer',
    getTicketHere: 'Trekk kølapp her',
    otherServices: 'Andre tjenester',
    digitalTicketOnPhone: 'Digital kølapp på mobiltelefon',
    scanQrForDigitalTicket:
      'Skann QR-kode for elektronisk kølapp. Du kan da følge køen på din mobiltelefon, og fortsette handelen mens du venter på din tur.',
    cannotPrintPaperTicket: 'Kan ikke printe ut papirkølapp, vennligst scan QR-koden over.',
    forPaperTicket: 'For papirbasert kølapp:',
    qrBased_savePaper: 'SPAR VERDEN FOR PAPIR',
    qrBased_useMobileCamera: 'BRUK MOBILKAMERAET TIL Å TA EN DIGITAL KØLAPP',
    qrBased_instructions: 'INSTRUKSJONER',
    qrBased_instructionStep1: 'Åpne mobilkameraet.',
    qrBased_instructionStep2: 'Hold mobilen slik at QR-koden vises på mobilskjermen.',
    qrBased_instructionStep3: 'Trykk på varslingen som vises på mobilskjermen.',
    qrBased_instructionStep4: 'Velg den avdelingen du vil besøke.',
  },
  'en-US': {
    welcomeTo: 'Welcome to',
    printingToken: 'Printing out ticket',
    yourQueueNumber: 'Your queue number',
    getTicketHere: 'Get queue slip here',
    otherServices: 'Other services',
    digitalTicketOnPhone: 'Digital queue slip on mobile phone',
    scanQrForDigitalTicket:
      'Scan QR code for electronic queue slip. You can then follow the queue on your mobile phone, and continue shopping while you wait for your turn.',
    cannotPrintPaperTicket: 'Cannot print paper queue slip, please scan the QR code above.',
    forPaperTicket: 'For paper-based queue slip:',
    qrBased_savePaper: 'SAVE UNECESARRY USAGE OF PAPER',
    qrBased_useMobileCamera: 'USE YOUR MOBILE CAMERA TO GET A DIGITAL QUEUEING TICKET',
    qrBased_instructions: 'INSTRUCTIONS',
    qrBased_instructionStep1: `Open your phone's camera app.`,
    qrBased_instructionStep2: 'Point the camera on the QR code.',
    qrBased_instructionStep3: 'Press on the notification that appears on the display.',
    qrBased_instructionStep4: 'Choose the queue you wish to get in line for.',
  },
};
