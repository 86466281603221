import { animate, keyframes, style, transition, trigger, state } from '@angular/animations';

export const heightTransition = trigger('aniHeightTransition', [
  transition(':enter', [
    style({ opacity: 0, height: 0 }),
    animate('400ms cubic-bezier(0.34, 1.56, 0.64, 1)', style({ opacity: 1, height: '*' })),
  ]),
  transition(':leave', [
    style({ opacity: 1, height: '*' }),
    animate('400ms cubic-bezier(0.34, 1.56, 0.64, 1)', style({ height: 0, opacity: 0 })),
  ]),
]);

export const BLINK_ANIMATION_DURATION = 4000;

// Legacy animation for new token on display
export const blinkAnimation = (triggerName = 'blinkAnimation') => {
  return trigger(triggerName, [
    transition(
      '* => 1',
      animate(
        BLINK_ANIMATION_DURATION,
        keyframes([
          style({ opacity: '0.25' }),
          style({ opacity: '1.0' }),
          style({ opacity: '0.25' }),
          style({ opacity: '1.0' }),
          style({ opacity: '0.25' }),
          style({ opacity: '1.0' }),
        ])
      )
    ),
  ]);
};

const getTokenFlashAnimationFrames = (repitions = 4) =>
  [...Array(repitions).keys()].reduce(
    (acc) => [...acc, style({ filter: 'brightness(1)' }), style({ filter: 'brightness(0.4)' })],
    []
  );

// New animation for new token on display
export const tokenFlashAnimation = (triggerName = 'tokenFlashAnimation') => [
  trigger(triggerName, [transition('* => 1', animate('6000ms', keyframes(getTokenFlashAnimationFrames(8))))]),
];

export const tokenIntvertColorFlashAnimation = (triggerName = 'tokenIntvertColorFlashAnimation') => [
  trigger(triggerName, [
    state('flash1', style({ color: '{{fontColor}}', 'background-color': '{{bgColor}}' }), {
      params: { fontColor: '#ffffff', bgColor: '#2b2b2b' },
    }),
    state('flash2', style({ color: '{{bgColor}}', 'background-color': '{{fontColor}}' }), {
      params: { fontColor: '#ffffff', bgColor: '#2b2b2b' },
    }),
    state('original', style({ color: '{{fontColor}}', 'background-color': '{{bgColor}}' }), {
      params: { fontColor: '#ffffff', bgColor: '#2b2b2b' },
    }),
    transition('flash1 => flash2', animate('600ms')),
    transition('flash2 => flash1', animate('600ms')),
    transition('flash1 => original', animate('800ms')),
    transition('flash2 => original', animate('800ms')),
  ]),
];

export const triggerTokenIntvertColorFlashAnimation = (component, timeoutKey, flagKey, stateKey) => {
  const ANIMATION_DURATION = 6000;
  if (component[timeoutKey]) clearTimeout(component[timeoutKey]);

  if (!component[flagKey]) {
    component[flagKey] = true;
    component[stateKey] = 'flash1';
  }

  component[timeoutKey] = setTimeout(() => {
    component[flagKey] = false;
    component[stateKey] = 'original';
  }, ANIMATION_DURATION);
};

export const tokenIntvertColorFlashAnimationDone = (component, flagKey, stateKey) => {
  if (component[flagKey]) {
    setTimeout(
      () => {
        component[stateKey] = component[stateKey] === 'flash1' ? 'flash2' : 'flash1';
      },
      component[stateKey] === 'flash1' ? 500 : 0
    );
  } else component[stateKey] = 'original';
};
