import { BranchQueueOverview } from './branch-queue-overview';
import { Mood } from './mood';

export class QueueOverview {
  public id: string;
  public name: string;
  public queues: Array<BranchQueueOverview>;
  public value: string;
  public label: string;
  public mood: Mood;

  constructor() {
    this.queues = new Array<BranchQueueOverview>();
    this.mood = new Mood();
  }

  setQueueOverviewData(data) {
    this.id = data.id;
    this.name = data.attributes.name;
    this.value = this.id;
    this.label = this.name;
    this.setBranchQueuesData(data, this.mood);
  }

  setBranchQueuesData(data, mood) {
    let queues_list = data.attributes.queues.data;
    let branchQueueOverviews = new Array<BranchQueueOverview>();

    for (var i = 0; i < queues_list.length; i++) {
      let queue = new BranchQueueOverview();
      queue.setBranchQueueOverviewData(queues_list[i], mood);
      branchQueueOverviews.push(queue);
    }

    this.queues = branchQueueOverviews;
  }
}
