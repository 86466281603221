import { Component, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import {
  DataService,
  EmployeeConsoleEndpointsService,
  BranchEndpointService,
  MainPageEndpointsService,
} from '../common/index';
import { NewTokenData, PusherService } from 'app/common/_services/pusher.service';
import { CounterDisplayComponent } from '../counter-display/counter-display.component';

@Component({
  selector: 'roof-counter',
  templateUrl: './roof-counter.component.html', // copy from CounterDisplayComponent
  styleUrls: ['../counter-display/counter-display.component.scss'],
  providers: [EmployeeConsoleEndpointsService],
})
export class RoofCounterComponent extends CounterDisplayComponent {
  constructor(
    private employeeService: EmployeeConsoleEndpointsService,
    public mainPageEndpointsService: MainPageEndpointsService,
    public route: ActivatedRoute,
    public router: Router,
    public dataService: DataService,
    public branchService: BranchEndpointService,
    public pusherService: PusherService,
    public ngZone: NgZone
  ) {
    super(mainPageEndpointsService, route, router, dataService, branchService, pusherService, ngZone);
  }

  loadData() {
    this.employeeService.getCounterRoofData(this.urlCounterId, this.urlBranchQueueId).subscribe(
      (res: any) => {
        if (!res?.data) return;

        const { data } = res;
        this.counters[0] = this.mapToCounterItem(data);

        const { showIdleScreenAfterInactivity } = this.customization || {};
        if (!showIdleScreenAfterInactivity) this.setShowIdleScreen(false);

        this.setLatestTokenNumber();
      },
      (error) => {
        this.alertMessage(error);
      }
    );
  }

  handleNewTokenData = (payload: NewTokenData) => {
    if (!payload) return;

    const counter = this.counters[0];
    const { attributes } = payload.data;
    const { counterId, status, tokenNumber, totalPeopleInQueue } = attributes;

    if (status === 'pending' && totalPeopleInQueue === 1) {
      return this.branchService.playTokenEventSound(this.branchService.soundEffects.newToken);
    } else if (counterId == counter?.id && status === 'serving') {
      this.setIdleScreenTimeout();

      this.counters[0].currentTokenNumber = this.mapToTokenData(payload);
      this.latestTokenNumber = tokenNumber;

      this.triggerTokenAnimation();

      if (counter.nextTokenSoundEnabled) {
        this.branchService.playTokenEventSound(null, tokenNumber);
      }
    }
  };
}
