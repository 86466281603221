import { Component, OnInit, NgZone, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService, FeedbackStandEndpointsService, FeedbackStand, GlobalVariables, MoodEnum } from 'app/common';
import { AuthButtonService } from 'app/auth-button';

declare var swal: any;

@Component({
  selector: 'feedback-stand-side',
  templateUrl: './feedback-stand-side.component.html',
	styleUrls: ['./feedback-stand-side.component.scss'],
	providers: [FeedbackStandEndpointsService]
})

export class FeedbackStandSideComponent {

	@Input('feedbackStandData') feedbackStandData;
	message;
	moodSelected = false;
	notIndifferent;
	notBad;
	notGood;
	notExcellent;
	questions;
	currentMood;

  constructor(private router: Router,
              private dataService: DataService,
              private feedbackStandEndpointsService: FeedbackStandEndpointsService,
              private zone: NgZone) {}

  refreshPage() {
    location.reload();
  }
}
