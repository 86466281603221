import { Component, OnInit, NgZone } from '@angular/core';
import { AnimationEvent } from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { animations, ROLL_UP_TRANSITION_TIME } from './recent_queue.animation';

import {
  DataService,
  RecentQueueEndpointsService,
  GlobalVariables,
  RecentQueue,
  BranchEndpointService,
} from '../common/index';
import { AuthButtonService } from '../auth-button/index';
import { NewTokenData, PusherService } from 'app/common/_services/pusher.service';

declare var swal: any;

interface RollUpAnimation {
  showCounter: boolean;
  showTokenNumber: boolean;
  currentCounter?: string;
  animationQueue: { tokenNumber: string; counter: string }[];
}

// ! If more view types are added, should probably refactor into multiple components like printers in master_http
@Component({
  selector: 'recent-queue',
  templateUrl: './recent_queue.component.html',
  styleUrls: ['./recent_queue.component.scss'],
  providers: [RecentQueueEndpointsService],
  animations,
})
export class RecentQueueComponent implements OnInit {
  recentQueueData: RecentQueue;
  routeId: number;
  loggedIn: string;

  animateClassicFlag: 'active' | 'inactive';
  animateRollupFlag: 'active' | 'inactive';

  rollUpAnimation: RollUpAnimation = {
    showCounter: false,
    showTokenNumber: true,
    currentCounter: undefined,
    animationQueue: [],
  };

  inactivityTimeout: NodeJS.Timeout;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService,
    private authButtonService: AuthButtonService,
    private recentQueueEndpointsService: RecentQueueEndpointsService,
    private zone: NgZone,
    private pusherService: PusherService,
    private branchService: BranchEndpointService
  ) {
    this.validateLogin();
  }

  ngOnInit() {
    if (this.loggedIn) {
      this.routeParams();
      this.loadRecentQueue();
      this.initPusherSubscription();

      this.zone.run(() => {
        console.log('enabled page re-render');
      });
    }
  }

  initPusherSubscription() {
    const branchId = this.route.snapshot.params['branchId'];
    this.pusherService.connectTokenBroadcast(branchId, this.handleNewTokenData);
  }

  handleNewTokenData = (data: NewTokenData) => {
    const { view } = this.recentQueueData;
    const { branchQueueId, tokenNumber, status } = data.data.attributes;

    if (branchQueueId === this.recentQueueData.branchQueueId && status === 'serving') {
      if (this.recentQueueData.showIdleScreenAfterInactivity) {
        if (this.inactivityTimeout) clearTimeout(this.inactivityTimeout);

        this.inactivityTimeout = setTimeout(() => {
          this.recentQueueData.servingTokenNumber = null;
        }, this.recentQueueData.inactivtyTimer * 60 * 1000);
      }

      if (view === 'rollup_animated') {
        this.animateRollUpNewToken(data);
        return;
      }

      this.branchService.playTokenEventSound(null, tokenNumber);
      this.recentQueueData.servingTokenNumber = tokenNumber;
      this.playNewServingTokenAnimation(data);
    }
  };

  playNextRollUpAnimation() {
    if (this.rollUpAnimation.animationQueue.length === 0) return;

    const visibleCounterDuration = 1000;
    const { tokenNumber, counter } = this.rollUpAnimation.animationQueue[0];

    this.branchService.playTokenEventSound(null, tokenNumber);
    this.recentQueueData.servingTokenNumber = tokenNumber;
    this.recentQueueData.currentCounterName = counter;

    const getNextStepTransitionTime = (step: number) => ROLL_UP_TRANSITION_TIME * step;

    this.rollUpAnimation.showTokenNumber = false;
    setTimeout(() => (this.rollUpAnimation.showCounter = true), getNextStepTransitionTime(1));
    setTimeout(
      () => (this.rollUpAnimation.showCounter = false),
      getNextStepTransitionTime(2) + visibleCounterDuration
    );
    setTimeout(() => {
      this.rollUpAnimation.showTokenNumber = true;
      this.animateClassicFlag = 'active';
    }, getNextStepTransitionTime(3) + visibleCounterDuration);
  }

  animateRollUpNewToken(token: NewTokenData) {
    const { animationQueue } = this.rollUpAnimation;
    const isEmpty = animationQueue.length === 0;

    this.rollUpAnimation.animationQueue.push({
      tokenNumber: token.data.attributes.tokenNumber,
      counter: token.data.attributes.counterName,
    });

    if (isEmpty) this.playNextRollUpAnimation();
  }

  playNewServingTokenAnimation(token: NewTokenData) {
    const { view } = this.recentQueueData;

    if (view === 'rollup_animated') this.animateRollUpNewToken(token);
    else {
      this.animateClassicFlag = 'active';
      setTimeout(() => (this.animateClassicFlag = 'inactive'), 4000);
    }
  }

  validateLogin() {
    this.loggedIn = this.dataService.sessionData();
    if (!this.loggedIn) this.router.navigate(['/login']);
    this.authButtonService.login();
  }

  routeParams() {
    this.route.params.subscribe((params) => {
      this.routeId = +params['id'];
    });
  }

  loadRecentQueue() {
    this.recentQueueEndpointsService.getRecentQueueData(this.routeId).subscribe(
      (data) => {
        this.recentQueueData = new RecentQueue(data.recentQueueData, data.customization);
      },
      (error) => {
        swal({
          text: this.errorMessage(error),
          type: 'warning',
          allowOutsideClick: false,
          showCancelButton: false,
          allowEscapeKey: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Refresh the page!',
        }).then(() => {
          if (error && error[0].status == '401') {
            this.dataService.clearData();
            this.router.navigate(['login']);
          } else {
            location.reload();
          }
        });
      }
    );
  }

  refreshPage() {
    location.reload();
  }

  animateClassicDone($event) {
    this.animateClassicFlag = 'inactive';
  }

  animateRollUpDone(event: AnimationEvent) {
    const { phaseName, fromState } = event;

    if (phaseName === 'done' && fromState === 'void') {
      setTimeout(() => {
        if (this.rollUpAnimation.animationQueue.length > 0) {
          this.rollUpAnimation.animationQueue.shift();
          this.rollUpAnimation.currentCounter = undefined;
        }
        this.playNextRollUpAnimation();
      }, 1500);
    }
  }

  errorMessage(error) {
    let message;

    if (error[0] && error[0].status == '404') {
      message = GlobalVariables.ERROR_MESSAGE_404;
    } else if (error[0] && error[0].message) {
      message = error[0].message;
    } else {
      message = GlobalVariables.DEFAULT_ERROR_MESSAGE;
    }

    return message;
  }
}
