import { BranchQueue } from './branch-queue';

export class Printer {
  public id;
  public name;
  public logo;
  public branchQueue: Array<BranchQueue>;
  public value;
  public label;
  public qrCodeText;
  public qrCodeEnabled;
  public ipAddress;
  public qrSize;
  public attachments;
  public viewType;
  public backgroundColor;
  public buttonBackgroundColor;
  public buttonTextColor;
  public labelColor;
  public buttonBorderColor;
  public tokenFontColor;
  public tokenFontFamily;
  public tokenBackgroundColor;

  setPrinterData(data) {
    this.id = data.id;
    this.name = data.attributes.name;
    this.value = this.id;
    this.label = this.name;
    this.qrCodeText = data.attributes.qrCode;
    this.branchQueue = new Array<BranchQueue>();
    this.qrCodeEnabled = data.attributes.enableQrCode;
    this.ipAddress = data.attributes.ipAddress;
    this.attachments = data.attributes.attachments;
    this.viewType = data.attributes.view;
    this.backgroundColor = data.attributes.backgroundColor;
    this.buttonBackgroundColor = data.attributes.buttonBackgroundColor;
    this.buttonTextColor = data.attributes.buttonTextColor;
    this.labelColor = data.attributes.labelColor;
    this.buttonBorderColor = data.attributes.buttonBorderColor;
    this.tokenFontColor = data.attributes.tokenCustomization.font_color;
    this.tokenFontFamily = data.attributes.tokenCustomization.font_family;
    this.tokenBackgroundColor = data.attributes.tokenCustomization.background_color;
  }

  setQueuesData(data) {
    for (var i = 0; i < data.length; i++) {
      let queue = new BranchQueue();
      queue.setBranchQueueData(data[i]);
      this.branchQueue.push(queue);
    }
  }

  setAttachmentsImage(data) {
    if (data.attributes.attachments) {
      this.attachments = data.attributes.attachments;
    }
  }
}

export class QrCode {
  public isBannerImage;
  public animationName;
  public qrClicked;
  public qrForeground;
  public qrBackground;

  constructor() {
    this.isBannerImage = true;
    this.qrClicked = false;
    this.qrForeground = this.defaultQrForeground();
    this.qrBackground = this.defaultQrBackground();
  }

  defaultQrForeground() {
    return '#000000';
  }

  defaultQrBackground() {
    return '#f4f5f6';
  }
}
