import { HttpErrorResponse } from '@angular/common/http';
import * as Sentry from '@sentry/angular';
import { throwError } from 'rxjs';
import { GlobalVariables } from '../global';

export const handleApiErrors = (httpErrorResponse: HttpErrorResponse) => {
  if (httpErrorResponse?.error?.errors) {
    return throwError(httpErrorResponse.error.errors);
  }

  Sentry.captureException(httpErrorResponse);

  return throwError([
    {
      status: 500,
      message: 'Unexpected error happened, please contact Qtime Support for further assistance',
      title: 'Unknown Error',
    },
  ]);
};

export function getErrorMessage(error) {
  if (error?.[0]?.status == '404') {
    return GlobalVariables.ERROR_MESSAGE_404;
  } else if (error?.[0]?.message) {
    return error[0].message;
  }

  return GlobalVariables.DEFAULT_ERROR_MESSAGE;
}
