export class BranchQueueMonitorOverview {
  public id;
  public name;
  public branchQueueId;
  public servingTokenNumber;
  public currentTokenNumber;
  public backgroundColor1;
  public backgroundColor2;
  public fontColor;
  public sound;
  public peopleInQueue = 0;
  public counterName = '';

  setBranchQueueMonitorOverviewData(data) {
    this.id = data.id;
    this.name = data.attributes.displayName || data.attributes.name;
    this.branchQueueId = data.attributes.branchQueueId;
    this.servingTokenNumber = data.attributes.servingTokenNumber;
    this.currentTokenNumber = data.attributes.currentTokenNumber;
    this.backgroundColor1 = data.attributes.backgroundColor1;
    this.backgroundColor2 = data.attributes.backgroundColor2;
    this.fontColor = data.attributes.fontColor;
    this.sound = data.attributes.sound;
    this.peopleInQueue = data.attributes.peopleInQueue;
  }
}
