import { RecentQueueView, RecentQueueData, CounterCustomizationData } from '../index';

export class RecentQueue {
  DEFAULT_FONT_FAMILY = 'Roboto';
  DEFAULT_FONT_COLOR = '#fff';
  DEFAULT_BACKGROUND_COLOR = '#384148';
  DEFAULT_FONT_SIZE = 43;
  DEFAULT_BORDER_COLOR = '#1f2343';

  public id;
  public name;
  public queue_name;
  public currentCounterName?: string;
  public value;
  public label;
  public fontFamily;
  public fontFileUrl;
  public fontColor;
  public backgroundColor;
  public logo;
  public servingTokenNumber;
  public sound;
  public queueFontSize;
  public tokenFontSize;
  public branchQueueId: number;
  public view: RecentQueueView;
  public inactivtyTimer: number;
  public showIdleScreenAfterInactivity: boolean;

  constructor(recentQueueData: RecentQueueData, customization?: CounterCustomizationData) {
    const { data } = recentQueueData || {};
    if (!data) return;

    this.id = data.id;
    this.branchQueueId = data.attributes.branchQueueId;
    this.name = data.attributes.name;
    this.queue_name = data.attributes.branchQueueName;
    this.servingTokenNumber = data.attributes.servingTokenNumber;
    this.sound = data.attributes.sound;
    this.logo = data.attributes.logo;
    this.view = data.attributes.view;
    this.setCustomization(customization);
  }

  setCustomization(customization: CounterCustomizationData) {
    if (!customization) return;

    const { attributes } = customization?.data || {};
    this.inactivtyTimer = attributes?.inactivityTimer;
    this.showIdleScreenAfterInactivity = attributes?.showIdleScreenAfterInactivity;
    this.backgroundColor = attributes.backgroundColor;
    this.fontColor = attributes.fontColor;
    this.fontFamily = attributes.tokenFontFamily || this.DEFAULT_FONT_FAMILY;
    this.queueFontSize = attributes.counterFontSize;
    this.tokenFontSize = attributes.tokenFontSize;

    if (this.showIdleScreenAfterInactivity) {
      this.servingTokenNumber = null;
    }
  }
}
