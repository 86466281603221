import { MoodyEnum } from '../_classes';

export function calcWaitingTime(createdAt: string): number {
  const createdTime = new Date(createdAt).getTime();
  return new Date().getTime() - createdTime;
}

export function getMoodByWaitTime(waitTime: number, mood: { happy: number; neutral: number }) {
  if (waitTime < mood.happy) return MoodyEnum.happy;
  if (waitTime < mood.neutral) return MoodyEnum.neutral;
  return MoodyEnum.sad;
}
