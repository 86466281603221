
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GlobalVariables } from '../global';
import { DataService } from './data.service';
import { handleApiErrors } from '../_utils/api.util';

@Injectable()
export class ViewBoardEndpointsService {
  private basicUrl = GlobalVariables.BASE_API_URL + '/api/v1/branches';
  constructor(private http: HttpClient, private dataService: DataService) {}

  getBranchData() {
    let url = this.basicUrl + '/' + this.dataService.branchData();
    return this.http.get(url).pipe(catchError((err) => handleApiErrors(err)));
  }

  getPendingTokens(branchQueueId) {
    let url = this.basicUrl + '/' + this.dataService.branchData() + '/branch_queues/' + branchQueueId + '/tokens';
    return this.http.get(url).pipe(catchError((err) => handleApiErrors(err)));
  }

  getConsoles(queueId, consoleIds = null) {
    let filterSet = consoleIds ? '&counter_ids=' + consoleIds : '';
    let url =
      this.basicUrl +
      '/' +
      this.dataService.branchData() +
      '/branch_queues/' +
      queueId +
      '/counters?include=serving_token_for_current_day' +
      filterSet;

    return this.http.get<any>(url).pipe(catchError((err) => handleApiErrors(err)));
  }

  getBranchQueueData(id) {
    let url = this.basicUrl + '/' + this.dataService.branchData() + '/branch_queues/' + id;
    return this.http.get(url).pipe(catchError((err) => handleApiErrors(err)));
  }
}
