import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({ selector: '[ticker]' })
export class TickerDirective implements OnInit {

    margin: number;
    interval: any;
    firstNode: any;
    view: any[];
    textWidth: number;
    idle: boolean;

    @Input('speed') speed: number;
    @Input('padding-right') paddingRight: number;
    @Input('size') size: number;
    @Input('trigger') trigger: string;
    @Input('text') text: string;

    constructor(private el: ElementRef, private r: Renderer2) {}

    initTicker(): void {
        this.margin = 0;

        this.view = [
            this.createTickerNode( '<T>', this.text ),
            this.createTickerNode( '<T>', this.text )
        ];

        __ngRendererAttachViewAfterHelper(this.r, this.firstNode, this.view);
        this.moveLeft();
    }

    ngOnInit(): void {
        this.setIgnoredAtts();
        this.textWidth = this.getTextWidth();
        this.firstNode = this.createTickerNode( this.firstNode, this.text );
        if ( this.trigger === 'auto' || this.tickerNeeded()) {
            this.initTicker();
        }
    }

    setIgnoredAtts(): void {
        if ( !this.paddingRight ) { this.paddingRight = 16; }
        if ( !this.speed )        { this.speed = 25; }
        if ( !this.trigger )      { this.trigger = 'onMouseEnter'; }
        if ( !this.size )         { this.size = 16; }
        if ( !this.text )         { this.text = 'You need to add the [text] attribute to the "ticker" directive'; }
        this.idle = true;
    }

    createTickerNode( self: any , text: string ): any {
        self = __ngRendererCreateElementHelper(this.r, this.el.nativeElement, 'span');
        this.r.setStyle(self, 'padding-right', this.paddingRight + 'px');
        this.r.setStyle(self, 'font-size', this.size + 'vh');

        self.innerHTML = this.text + '&nbsp; &nbsp;';
        return self;
    }

    moveLeft(): void {
        let resetMargin = ( this.textWidth + this.paddingRight ) * -2 ;
        this.interval = setInterval(() => {
            this.r.setStyle(this.firstNode, 'margin-left', this.margin-- + 'px');
            if (this.margin < resetMargin) { this.margin = 0; }
        }, this.speed);
    }

    getTextWidth(): number {
        let t = __ngRendererCreateElementHelper(this.r, document.getElementById('ghost'), 'div');

        t.innerHTML = this.text;

        this.r.setStyle(t, 'font-size', this.size + 'vh');
        let w = t.offsetWidth;
        t.innerHTML = '';
        return w;
    }

    tickerNeeded(): boolean {
        return this.textWidth > this.el.nativeElement.parentElement.offsetWidth - 2;
    }
}

type AnyDuringRendererMigration = any;

function __ngRendererAttachViewAfterHelper(renderer: AnyDuringRendererMigration, node: AnyDuringRendererMigration, rootNodes: AnyDuringRendererMigration) {
    const parent = renderer.parentNode(node);
    const nextSibling = renderer.nextSibling(node);
    for (let i = 0; i < rootNodes.length; i++) {
        renderer.insertBefore(parent, rootNodes[i], nextSibling);
    }
}

function __ngRendererSplitNamespaceHelper(name: AnyDuringRendererMigration) {
    if (name[0] === ":") {
        const match = name.match(/^:([^:]+):(.+)$/);
        return [match[1], match[2]];
    }
    return ["", name];
}

function __ngRendererCreateElementHelper(renderer: AnyDuringRendererMigration, parent: AnyDuringRendererMigration, namespaceAndName: AnyDuringRendererMigration) {
    const [namespace, name] = __ngRendererSplitNamespaceHelper(namespaceAndName);
    const node = renderer.createElement(name, namespace);
    if (parent) {
        renderer.appendChild(parent, node);
    }
    return node;
}
