import { trigger, style, transition, animate, keyframes } from '@angular/animations';

export const ROLL_UP_TRANSITION_TIME = 500;
export const CLASSIC_TRANSITION_TIME = 4000;

export const animations = [
  trigger('classicTokenAnimation', [
    transition(
      '* => active',
      animate(
        CLASSIC_TRANSITION_TIME,
        keyframes([
          style({ opacity: '0.0' }),
          style({ opacity: '1.0' }),
          style({ opacity: '0.0' }),
          style({ opacity: '1.0' }),
          style({ opacity: '0.0' }),
          style({ opacity: '1.0' }),
        ])
      )
    ),
  ]),
  trigger('rollUpAnimation', [
    transition(':enter', [
      style({ transform: 'translate(-50%, 160vh)' }),
      animate(`${ROLL_UP_TRANSITION_TIME}ms cubic-bezier(0.34, 1.25, 0.64, 1)`, style({ transform: 'translate(-50%, -50%)' })),
    ]),
    transition(':leave', [
      style({ transform: 'translate(-50%, -50%)' }),
      animate(`${ROLL_UP_TRANSITION_TIME}ms cubic-bezier(0.34, 1.25, 0.64, 1)`, style({ transform: 'translate(-50%, -160vh)' })),
    ]),
  ]),
];
