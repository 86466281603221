<div id="vertical-monitor" class="container-fluid">
  <div class="row">
    <div class="banner-wrapper">
      <div class="col-xs-12 monitor-image clear-margin-padding">
        <div *ngIf="!isVideoCampaign" id="verticalMonitorSliderFrame"></div>
        <div *ngIf="isVideoCampaign" id="verticalMonitorVideo">
          <iframe [src]="iframeSrc" frameborder="0" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
</div>
