
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import { GlobalVariables } from '../global';
import { handleApiErrors } from '../_utils/api.util';

@Injectable()
export class QueueOverviewEndpointsService {
  private basicUrl = GlobalVariables.BASE_API_URL + '/api/v1/branches';
  constructor(private http: HttpClient, private route: ActivatedRoute) {}

  getQueueOverviewData(id) {
    let url = this.basicUrl + '/' + this.route.snapshot.params['branchId'] + '/queue_overviews/' + parseInt(id);
    return this.http.get(url).pipe(catchError((err) => handleApiErrors(err)));
  }

  getBranchData() {
    let url = this.basicUrl + '/' + this.route.snapshot.params['branchId'];
    return this.http.get(url).pipe(catchError((err) => handleApiErrors(err)));
  }
}
