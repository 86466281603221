<div
  *ngFor="let queue of queues; let i = index"
  [ngClass]="{ active: queue.id == selectedQueue?.id }"
  (click)="handleQueueClick(queue)"
  class="queue-list-item"
>
  <div class="total-in-queue {{ queue.totalPeopleInQueue === 0 ? '--empty' : '' }}">
    <i class="user-icon"></i>
    <span>{{ queue.totalPeopleInQueue }}</span>
  </div>
  <span class="queue-label">{{ queue.name }}</span>
</div>
