import { timer as observableTimer, interval as observableInterval, Observable, Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Pipe } from '@angular/core';
import {
  GlobalVariables,
  DataService,
  EmployeeConsole,
  EmployeeConsoleEndpointsService,
  MainPageEndpointsService,
  BranchQueue,
  ViewBoardEndpointsService,
  Token,
  MoodyEnum,
} from '../common/index';
import { AuthButtonService } from '../auth-button/index';
import { NewTokenData, PusherService } from 'app/common/_services/pusher.service';

declare var swal: any;

@Component({
  selector: 'employee-console',
  templateUrl: './employee-console.component.html',
  styleUrls: ['./employee-console.component.scss'],
  providers: [MainPageEndpointsService, EmployeeConsoleEndpointsService, ViewBoardEndpointsService],
})
export class EmployeeConsoleComponent implements OnInit, OnDestroy {
  dateToday;
  branchQueue;
  queueInstance;
  tokens;
  branchData;
  queuesLoaded = true;
  tokensLoaded = false;
  message;
  waitTimesSub; // Subscriber for timer
  counterId;
  branchQueueId;
  employee: EmployeeConsole;
  loggedIn;
  showHoverView = false;
  requestLocked = false;

  constructor(
    private router: Router,
    private authButtonService: AuthButtonService,
    private dataService: DataService,
    private route: ActivatedRoute,
    private employeeService: EmployeeConsoleEndpointsService,
    private mainPageEndpoints: MainPageEndpointsService,
    private viewBoardEndpoints: ViewBoardEndpointsService,
    private zone: NgZone,
    private pusherService: PusherService
  ) {
    this.validateLogin();
    this.setBodyClass();
    this.dateToday = new Date();
    this.employee = new EmployeeConsole();

    this.loadBranch();
    this.loadLogo();
    this.dateTicker();
  }

  setBodyClass() {
    let device = navigator.userAgent.toLowerCase();
    let body = document.getElementsByTagName('body')[0];

    if (/ipad|iphone|ipod/.test(device)) {
      body.classList.add('ios-devices');
    }
  }

  ngOnInit() {
    if (this.loggedIn) {
      this.routeParams();
      this.loadConsole();

      const branchId = this.route.snapshot.params['branchId'];

      this.pusherService.connectTokenBroadcast(branchId, this.handleNewTokenData);

      this.zone.run(() => {
        console.log('enabled page re-render');
      });
    }
  }

  ngOnDestroy() {
    this.dateToday = null;
    this.counterId = null;
    this.branchQueueId = null;
    this.employee = null;
    this.loggedIn = null;
    this.showHoverView = null;
    this.requestLocked = null;
    this.pusherService?.tokenBroadcastChannel?.disconnect();
  }

  handleNewTokenData = (res: NewTokenData) => {
    if (!res || !res.data) return;

    const { attributes, id } = res.data;
    const { branchQueueId } = this.employee;

    if (+attributes.branchQueueId !== +branchQueueId) return;

    this.employee.peopleInQueue = attributes.totalPeopleInQueue;

    let tokenInstance = new Token();
    tokenInstance.setTokenData(res.data);

    switch (attributes.status) {
      case 'pending':
        tokenInstance.setMood(this.queueInstance.mood);
        this.tokens.push(tokenInstance);
        break;
      case 'no_show':
      case 'serving':
      case 'served':
        const tokenIndex = this.tokens.findIndex((x) => x.id == tokenInstance.id);
        tokenIndex > -1 && this.tokens.splice(tokenIndex, 1);

        if (attributes.counterId !== this.counterId) break;

        if (attributes.status === 'served') this.employee.peopleServed++;
        else if (attributes.status === 'no_show') this.employee.abandonments++;

        this.employee.setCurrentlyServing({ id, ...attributes });
        if (attributes.status === 'serving') this.employee.startServingTimer();
        else this.employee.cleanServingTimer();

        break;
      default:
        break;
    }
  };

  dateTicker() {
    observableInterval(1000).subscribe((x) => {
      this.dateToday = new Date();
    });
  }

  validateLogin() {
    this.loggedIn = this.dataService.sessionData();

    if (!this.loggedIn) {
      this.router.navigate(['/login']);
    }

    this.authButtonService.login();
  }

  routeParams() {
    this.route.params.subscribe((params) => {
      this.counterId = +params['id'];
      this.branchQueueId = +params['branchQueueId'];
    });
  }

  loadConsole() {
    this.employeeService.getConsoleData(this.counterId, this.branchQueueId).subscribe(
      (res: any) => {
        const { data } = res;
        this.employee.setConsoleData(data);
        if (this.employee.currentlyServing.status === 'serving') this.employee.startServingTimer();
        this.dataService.setCompanyLogo(data.attributes.logo);
        this.loadBranchQueue(data.relationships.branchQueue.data);
      },
      (error) => this.alertMessage(error)
    );
  }

  isEmpty(obj): boolean {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  isTokenStatus(data, status): boolean {
    if (this.isEmpty(data) || this.isEmpty(data.attributes)) {
      return false;
    }
    if (data.attributes.branchQueueId != this.branchQueue.id) {
      return false;
    }

    return data.attributes.status === status;
  }

  loadBranch() {
    this.employeeService.getBranchData().subscribe(
      (data: any) => {
        this.employee.branchName = data.data.attributes.name;
        this.branchData = data.data;
      },
      (error) => this.alertMessage(error)
    );
  }

  loadBranchQueue(queueData) {
    this.mainPageEndpoints.getCountersData(queueData).subscribe(
      (data: any) => {
        this.branchQueue = data.data;
        this.loadTokens();
      },
      (error) => this.alertMessage(error)
    );
  }

  // Calculate wait times for the list of tokens and set a time ticker on them
  waitingTimesForToken(): void {
    this.waitTimesSub = observableTimer(0, 1000).subscribe(() => {
      this.tokens?.forEach((it) => {
        it.waitTime = it.waitTime + 1;
        it.setMood(this.queueInstance.mood);
      });
    });
  }

  loadTokens(): void {
    this.queueInstance = new BranchQueue();
    this.queueInstance.setBranchQueueData(this.branchQueue);
    this.queueInstance.mood.setMoodData(this.branchData);

    this.viewBoardEndpoints.getPendingTokens(this.branchQueue.id).subscribe(
      (res: any) => {
        this.tokens = new Array<Token>();
        const { data } = res;

        data?.forEach((it) => {
          let tokenInstance = new Token();
          tokenInstance.setTokenData(it);
          tokenInstance.setMood(this.queueInstance.mood);
          this.tokens.push(tokenInstance);
        });

        this.waitingTimesForToken();
        this.tokensLoaded = true;
      },
      (error) => this.alertMessage(error)
    );
  }

  tokenMood(token): string {
    return MoodyEnum[token.mood];
  }

  alertMessage(error) {
    let message: string = GlobalVariables.DEFAULT_ERROR_MESSAGE;
    if (error?.[0]?.status == '404') message = GlobalVariables.ERROR_MESSAGE_404;
    else if (error?.[0]?.message) message = error[0].message;

    swal({
      text: message,
      type: 'warning',
      allowOutsideClick: false,
      showCancelButton: false,
      allowEscapeKey: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Refresh the page!',
    }).then(() => {
      this.requestLocked = false;
      if (error?.[0]?.status == '401') {
        this.dataService.clearData();
        this.router.navigate(['login']);
      } else {
        location.reload();
      }
    });
  }

  loadLogo() {
    this.employee.logoPath = this.dataService.CompanyLogo();
  }

  markServedEvent(counter: EmployeeConsole, tokenId?: number, branchQueueId?: number) {
    const hasParams = tokenId && branchQueueId;

    if (this.requestLocked) return;
    this.requestLocked = true;

    let params = [];
    if (hasParams) params = [tokenId, branchQueueId];

    this.employeeService.markServed(counter, ...params).subscribe(
      (res: any) => {
        if (res.data) {
          const { data } = res;
          this.employee.setCurrentlyServing(data.attributes.currentTokenNumber);
          this.employee.cleanServingTimer();
        }
        this.requestLocked = false;
      },
      (error) => this.alertMessage(error)
    );
  }

  recallEvent(counter) {
    if (this.requestLocked) return;
    this.requestLocked = true;

    this.employeeService.recallEvent(counter).subscribe(
      () => (this.requestLocked = false),
      (error) => this.alertMessage(error)
    );
  }

  nextEvent(counter) {
    this.employeeService.getNextToken(counter).subscribe(
      (res: any) => {
        if (!res.data) return;

        const { data } = res;
        const { attributes, id } = data;
        const { originType, branchQueueId } = attributes;

        if (originType === 'ticket_less') return this.markServedEvent(this.employee, id, branchQueueId);

        this.employee.setCurrentlyServing({ ...attributes, id });
        this.employee.startServingTimer();
      },
      (error) => this.alertMessage(error)
    );
  }

  noShowEvent(counter) {
    if (this.requestLocked) return;
    this.requestLocked = true;

    this.employeeService.noShowEvent(counter).subscribe(
      (res: any) => {
        if (res.data) {
          const { data } = res;
          this.employee.setCurrentlyServing(data.attributes.currentTokenNumber);
          this.employee.cleanServingTimer();
        }

        this.requestLocked = false;
      },
      (error) => this.alertMessage(error)
    );
  }

  showHover($event) {
    this.showHoverView = true;
  }

  hideHover($event) {
    if ($event.type == 'mouseout') {
      this.showHoverView = false;
    } else {
      setTimeout(() => {
        this.showHoverView = false;
      }, 500);
    }
  }

  truncateValue(value, arg): string {
    if (value) {
      let limit = parseInt(arg, 10);
      return value.length > limit ? value.substring(0, limit) + '...' : value;
    }

    return value;
  }
}
