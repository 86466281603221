import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataService, GlobalVariables } from './common';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(private dataService: DataService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = this.dataService.sessionData();

    let modifiedRequest = req;

    if (req.url.includes(GlobalVariables.BASE_API_URL)) {
      // cant make modifiedRequest.headers.setHeader() work
      // so, have to du a ternary expression for inclusion of access token

      modifiedRequest = req.clone({
        setHeaders: accessToken
          ? {
              'Content-Type': 'application/json',
              'ACCESS-TOKEN': accessToken,
            }
          : {
              'Content-Type': 'application/json',
            },
      });
    }

    return next.handle(modifiedRequest);
  }
}
