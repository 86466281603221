export function toggleAutoRedirectMode() {
  const autoRedirectMode = getAutoRedirectMode();
  const newValue = autoRedirectMode === 'true' ? 'false' : 'true';
  window.localStorage.setItem('autoRedirectMode', newValue);
  return newValue;
}

export function getAutoRedirectMode() {
  return window.localStorage.getItem('autoRedirectMode');
}

export function getAutoRedirectPath() {
  return window.localStorage.getItem('autoRedirectPath');
}

export function setAutoRedirectPath(url) {
  window.localStorage.setItem('autoRedirectPath', url);
}
