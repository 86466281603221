import { GlobalVariables } from '../index';

export class Ticket {
  DEFAULT_FONT_FAMILY = 'Roboto';
  DEFAULT_FONT_COLOR = '#fff';
  DEFAULT_BACKGROUND_COLOR = '#004795';

  public logo;
  public queueName;
  public currentTime;
  public peopleInQueue;
  public currentDate;
  public tokenNumber;
  public ticketStatus;
  public companyName;
  public ticketText;
  public attachment;
  public attachmentType;
  public fontFamily;
  public fontColor;
  public backgroundColor;

  constructor() {
    this.currentDate = this.currentTime = new Date();
    this.tokenNumber = '---';
    this.queueName = '';
    this.peopleInQueue = 0;
    this.ticketStatus = 'pending';
    this.companyName = '';
    this.fontFamily = this.DEFAULT_FONT_FAMILY;
    this.fontColor = this.DEFAULT_FONT_COLOR;
    this.backgroundColor = this.DEFAULT_BACKGROUND_COLOR;
  }
  setTicketData(data, queue) {
    if(data) {
      this.logo = data.attributes.logoOriginal;
      this.attachment = data.attributes.attachment; // This is a base64 encoded string
      this.attachmentType = data.attributes.attachmentType;
      this.queueName = queue.name;
      this.currentDate = this.currentTime = new Date();
      if(data.attributes.peopleInQueue != null) {
        this.peopleInQueue = data.attributes.peopleInQueue;
      }
      this.tokenNumber = data.attributes.tokenNumber;
      this.ticketStatus = data.attributes.status;
      this.companyName = data.attributes.companyName;
      this.ticketText = data.attributes.ticketText;
      this.fontFamily = data.attributes.fontFamily || this.DEFAULT_FONT_FAMILY;
      this.fontColor = data.attributes.fontColor || this.DEFAULT_FONT_COLOR;
      this.backgroundColor = data.attributes.backgroundColor || this.DEFAULT_BACKGROUND_COLOR;
    }
  }
}
