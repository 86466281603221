<nav class="navbar navbar-main">
  <div class="content-container">
    <div class="navbar-header">
      <i class="header-qtime-logo"></i>
      Qtime App (.net)
      <span class="navbar-header-version">v{{ version }}</span>
    </div>
    <div *ngIf="showLogout" class="nav navbar-nav navbar-right">
      <a href="{{ qtimeAppPrinterUrl }}"> To Qtime Printer (Wi-Fi) </a>
      <a (click)="logoutSession()"> Logout </a>
    </div>
  </div>
</nav>
