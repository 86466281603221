import { AuthGuard } from './common/_guards/auth.guard';
import { MonitorTokenDisplayComponent } from './monitor/views/monitor-token-display/monitor-token-display.component';
import { MonitorVrtComponent } from './monitor/views/monitor-vrt.component/monitor-vrt.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginPageComponent } from 'app/login-page/login-page.component';
import { LogoutComponent } from 'app/logout/logout.component';
import { EmployeeConsoleComponent } from 'app/employee-console/employee-console.component';
import { MonitorComponent } from 'app/monitor/monitor.component';
import { MonitorOverviewComponent } from 'app/monitor_overview/monitor_overview.component';
import { RecentQueueComponent } from 'app/recent_queue/recent_queue.component';
import { RoofCounterComponent } from 'app/roof-counter/roof-counter.component';
import { MainPageComponent } from 'app/main-page/main-page.component';
import { ViewBoardComponent } from 'app/view-board/view-board.component';
import { QueueOverviewComponent } from 'app/queue-overview/queue-overview.component';
import { PageNotFoundComponent } from 'app/page-not-found/page-not-found.component';
import { FeedbackStandComponent } from 'app/feedback-stand/feedback-stand.component';
import { SingleQueueInfoScreenComponent } from './single-queue-info-screen/single-queue-info-screen.component';
import { MonitorHorzComponent } from './monitor/views/monitor-horz/monitor-horz.component';
import { NameBasedCounterDisplayComponent } from './name-based-counter-display/name-based-counter-display.component';

const routes: Routes = [
  { path: 'login', component: LoginPageComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'main-page', component: MainPageComponent },
  { path: 'view-board', component: ViewBoardComponent },
  {
    path: 'branch/:branchId/branch-queue/:branchQueueId/employee-console/:id/console',
    component: EmployeeConsoleComponent,
  },
  { path: 'branch/:branchId/monitor/:id', component: MonitorComponent },
  { path: 'branch/:branchId/monitor/:id/horizontal', component: MonitorHorzComponent },
  { path: 'branch/:branchId/monitor/:id/horizontal_video', component: MonitorHorzComponent },
  { path: 'branch/:branchId/monitor/:id/no_advertisement', component: MonitorHorzComponent },
  { path: 'branch/:branchId/monitor/:id/vertical', component: MonitorVrtComponent },
  { path: 'branch/:branchId/monitor/:id/vertical_video', component: MonitorVrtComponent },
  { path: 'branch/:branchId/monitor/:id/token_display', component: MonitorTokenDisplayComponent },
  { path: 'branch/:branchId/monitor_overviews/:id', component: MonitorOverviewComponent },
  { path: 'branch/:branchId/queue_overviews/:id', component: QueueOverviewComponent },
  { path: 'branch/:branchId/recent_queues/:id', component: RecentQueueComponent },
  { path: 'branch/:branchId/single_queue_info_screen/:branchQueueId', component: SingleQueueInfoScreenComponent },
  {
    path: 'branch/:branchId/branch-queue/:branchQueueId/employee-console/:counterId',
    component: RoofCounterComponent,
  },
  { path: 'branch/:branchId/counter-display/:counterName', component: NameBasedCounterDisplayComponent },
  { path: 'branch/:branchId/feedback_stands/:id', component: FeedbackStandComponent },
  {
    path: 'branch/:branchId/printer/:id',
    loadChildren: () => import('./queue-printer/queue-printer.module').then((m) => m.QueuePrinterModule),
    canActivate: [AuthGuard],
  },
  { path: '', redirectTo: '/main-page', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
