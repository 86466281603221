import { Token } from './token';
import { Counter } from './counter';
import { Mood } from './mood';

export class BranchQueue {
  public id;
  public name;
  public currentToken: Token;
  public counters: Array<Counter>;
  public value;
  public label;
  public animationFlag;
  public hover;
  public totalPeopleInQueue;
  public userAbandons;
  public mood: Mood;
  public sound;

  constructor() {
    this.animationFlag = false;
    this.userAbandons = 0;
    this.mood = new Mood();
  }

  setBranchQueueData(data, displayName = '') {
    this.id = data.id;
    this.name = displayName || data.attributes.name;
    this.totalPeopleInQueue = data.attributes.peopleInQueue;
    this.userAbandons = data.attributes.userAbandons;
    this.value = this.id;
    this.label = this.name;
    this.currentToken = new Token();
    this.sound = data.attributes.sound;
    this.currentToken.setTokenSerializedData(data.attributes.currentTokenNumber);
    this.counters = new Array<Counter>();

    return [this.id, this.name, this.currentToken];
  }
}
