import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthButtonService } from '../auth-button/index';
import { AuthEndpointsService, BranchEndpointService, DataService, HeaderService } from '../common/index';
import { GetSignInResponse } from '../common/_types/api.types';

declare var swal: any;

@Component({
  selector: 'login',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  formData: any = {};

  constructor(
    private router: Router,
    private authButtonService: AuthButtonService,
    private authEndpointsService: AuthEndpointsService,
    private dataService: DataService,
    private headerService: HeaderService,
    private branchService: BranchEndpointService
  ) {
    let loggedIn = this.dataService.sessionData();

    if (loggedIn) {
      this.headerService.showLogout(true);
      this.router.navigate(['/main-page']);
    }
  }

  ngOnInit() {}

  submitted = false;

  onSubmit() {
    this.authEndpointsService.login(this.formData).subscribe(
      (data: GetSignInResponse) => {
        if (!data) return;

        let loginData = data.data;

        if (!loginData?.relationships) {
          this.authEndpointsService.logout();

          this.dataService.clearData();
          let message = 'Invalid Data Error: Please login again';
          this.alertMessage(message);
          return;
        }

        this.authButtonService.login();
        this.headerService.showLogout(true);
        this.router.navigate(['/main-page']);
      },
      (error) => {
        let message = error?.[0]?.message || 'Invalid Username and/or Password';
        this.alertMessage(message, error);
      }
    );
  }

  alertMessage(message, error = null) {
    swal({
      text: message,
      type: 'warning',
      allowOutsideClick: false,
      showCancelButton: false,
      allowEscapeKey: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Continue!',
    }).then(() => {
      if (error && error[0].status == '401') {
        this.dataService.clearData();
        this.router.navigate(['login']);
      } else {
        location.reload();
      }
    });
  }
}
