import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BranchQueue, EmployeeConsoleEndpointsService, GlobalVariables, Token } from '../../common';
import { EmployeeConsole } from '../../common/_classes/employee-console';
import { blinkAnimation, BLINK_ANIMATION_DURATION, heightTransition } from '../../common/common.animation';

@Component({
  selector: 'app-counter-console',
  templateUrl: 'counter-console.component.html',
  styleUrls: ['./counter-console.component.scss'],
  animations: [heightTransition, blinkAnimation()],
  encapsulation: ViewEncapsulation.None,
})
export class CounterConsoleComponent implements OnInit {
  @Input() counter: EmployeeConsole;
  @Input() requestLocked = false;
  @Input() branchQueue: BranchQueue;

  @Output() alertMessage = new EventEmitter();
  @Output() countPeopleServed = new EventEmitter();
  @Output() countAbandonments = new EventEmitter();
  @Output() requestLockedChange = new EventEmitter();
  @Output() removeCounterChange = new EventEmitter();

  nicknameControl = new FormControl();
  editing = false;

  blinkAnimationFlag = false;

  constructor(private employeeService: EmployeeConsoleEndpointsService) {}

  ngOnInit() {
    this.nicknameControl.setValue(this.counter?.nickname || '');
  }

  saveCounterNickname() {
    const { branchQueueId } = this.counter;
    const newValue = this.nicknameControl.value;

    this.employeeService.updateDisplayName(this.counter, branchQueueId, newValue).subscribe(
      (res: any) => {
        const { data } = res;

        if (data) {
          const { customName2 } = data.attributes;
          this.counter.nickname = customName2;
          this.nicknameControl.setValue(customName2);
        }

        this.requestLockedChange.emit(false);
        this.editing = false;
      },
      (error) => this.handleCounterError(error)
    );
  }

  setEditing(value: boolean) {
    this.editing = value;
  }

  handleSaveNicknamePress(event: KeyboardEvent) {
    if (event.code === 'Enter') this.saveCounterNickname();
  }

  handleSaveNicknameClick() {
    this.saveCounterNickname();
  }

  next() {
    if (this.requestLocked) return;
    this.requestLockedChange.emit(true);

    this.employeeService.getNextToken(this.counter).subscribe(
      ({ data }) => {
        if (data) {
          const { attributes, id } = data;
          const { originType, branchQueueId } = attributes;

          if (originType === 'ticket_less') return this.markServed(id, branchQueueId);

          this.counter.setCurrentlyServing({ ...attributes, id });
          this.counter.startServingTimer();
        } else {
          this.counter.currentlyServing = new Token();
        }

        this.requestLockedChange.emit(false);
      },
      (error) => this.handleCounterError(error)
    );
  }

  markServed(tokenId?: number, branchQueueId?: number) {
    const hasParams = tokenId && branchQueueId;

    if (this.requestLocked && !hasParams) return;
    this.requestLockedChange.emit(true);

    let params = [];
    if (hasParams) params = [tokenId, branchQueueId];

    this.employeeService.markServed(this.counter, ...params).subscribe(
      ({ data }) => {
        if (data) {
          const { attributes } = data;

          this.counter.abandonments = attributes.abandonments;
          this.counter.peopleServed = attributes.servedPeople;
          this.counter.setCurrentlyServing(attributes.currentTokenNumber);
          this.counter.cleanServingTimer();
        }

        this.requestLockedChange.emit(false);
      },
      (error) => this.handleCounterError(error)
    );
  }

  noShow() {
    if (this.requestLocked) return;
    this.requestLockedChange.emit(true);

    this.employeeService.noShowEvent(this.counter).subscribe(
      ({ data }) => {
        if (data) {
          const { attributes } = data;

          this.counter.peopleServed = attributes.servedPeople;
          this.counter.abandonments = attributes.abandonments;
          this.counter.setCurrentlyServing(attributes.currentTokenNumber);
          this.counter.cleanServingTimer();
        }

        this.requestLockedChange.emit(false);
        this.countAbandonments.emit();
      },
      (error) => this.handleCounterError(error)
    );
  }

  recall() {
    if (this.requestLocked) return;
    this.requestLockedChange.emit(true);

    this.employeeService.recallEvent(this.counter).subscribe(
      () => {
        this.playBlinkAnimation();
        this.requestLockedChange.emit(false);
      },
      (error) => this.handleCounterError(error)
    );
  }

  handleRemoveCounterClick() {
    this.counter.cleanServingTimer();
    this.removeCounterChange.emit(this.counter);
  }

  handleCounterError(error) {
    let message = '';

    if (error[0].status == '404') message = GlobalVariables.ERROR_MESSAGE_404;
    else message = GlobalVariables.DEFAULT_ERROR_MESSAGE;

    this.alertMessage.emit({ message, error });
  }

  playBlinkAnimation() {
    this.blinkAnimationFlag = true;
    setTimeout(() => (this.blinkAnimationFlag = false), BLINK_ANIMATION_DURATION);
  }
}
