import { Component } from '@angular/core';
import { MonitorComponent } from '../../monitor.component';
import { MonitorEndpointsService } from '../../../common';

@Component({
  selector: 'app-monitor-horz',
  templateUrl: './monitor-horz.component.html',
  styleUrls: ['./monitor-horz.component.scss'],
  providers: [MonitorEndpointsService],
})
export class MonitorHorzComponent extends MonitorComponent {}
