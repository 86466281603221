import { tokenIntvertColorFlashAnimationDone } from './../../../common/common.animation';
import { Component } from '@angular/core';
import { MonitorComponent } from '../../monitor.component';
import { MonitorEndpointsService } from '../../../common';
import {
  tokenIntvertColorFlashAnimation,
  triggerTokenIntvertColorFlashAnimation,
} from '../../../common/common.animation';

@Component({
  selector: 'app-monitor-token-display',
  templateUrl: './monitor-token-display.component.html',
  styleUrls: ['./monitor-token-display.component.scss'],
  providers: [MonitorEndpointsService],
  animations: [tokenIntvertColorFlashAnimation()],
})
export class MonitorTokenDisplayComponent extends MonitorComponent {
  timeout;

  public tokenAnimationFlag = false;
  public tokenAnimationState = 'original';
  public latestTokenNumber = '';

  private triggerTokenAnimation = () => {
    triggerTokenIntvertColorFlashAnimation(this, 'timeout', 'tokenAnimationFlag', 'tokenAnimationState');
  };

  public tokenAnimationDone = () => {
    tokenIntvertColorFlashAnimationDone(this, 'tokenAnimationFlag', 'tokenAnimationState');
  };

  private reduceLatestTokenNumber = (currentTokenFieldName) => (acc, val) => {
    if (!val[currentTokenFieldName]) return acc;
    const isNewer = acc < +val[currentTokenFieldName].id || 0;
    return isNewer ? val[currentTokenFieldName].tokenNumber : acc;
  };

  private getCurrentTokenNumber() {
    let tokenNumber = '';
    const isCounter = this.monitorData.itemType === 'counters';
    const monitorItems = isCounter ? this.counters : this.queues;
    const currentTokenFieldName = isCounter ? 'currentlyServing' : 'currentToken';
    tokenNumber = monitorItems.reduce(this.reduceLatestTokenNumber(currentTokenFieldName), 0);
    this.latestTokenNumber = tokenNumber ? `${tokenNumber}` : '';
  }

  onNewTokenServingHook(): void {
    this.getCurrentTokenNumber();
  }

  onMonitorDataLoadedHook(): void {
    this.getCurrentTokenNumber();
  }

  triggerMonitorItemAnimation(): void {
    this.triggerTokenAnimation();
  }

  monitorItemAnimationDone(): void {
    this.tokenAnimationDone();
  }
}
