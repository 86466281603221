<ng-container *ngIf="recentQueueData">
  <div
    *ngIf="recentQueueData.view === 'classic' || !recentQueueData?.view"
    class="queue-container"
    [ngStyle]="{
      background: recentQueueData.backgroundColor,
      color: recentQueueData.fontColor,
      fontFamily: recentQueueData.fontFamily
    }"
    (click)="refreshPage()"
  >
    <div class="queue-wrapper" *ngIf="recentQueueData.servingTokenNumber">
      <div
        class="queue-name"
        [ngStyle]="{
          fontSize: recentQueueData.queueFontSize + 'px'
        }"
      >
        {{ recentQueueData.queue_name }}
      </div>
      <div
        [@classicTokenAnimation]="animateClassicFlag"
        (@classicTokenAnimation.done)="animateClassicDone($event)"
        class="token-number"
      >
        {{ recentQueueData.servingTokenNumber }}
      </div>
    </div>

    <div class="idle-logo-container" *ngIf="!recentQueueData.servingTokenNumber">
      <img src="{{ recentQueueData.logo }}" />
    </div>
  </div>

  <div
    *ngIf="recentQueueData.view === 'rollup_animated'"
    class="queue-container"
    [ngStyle]="{
      background: recentQueueData.backgroundColor,
      color: recentQueueData.fontColor,
      fontFamily: recentQueueData.fontFamily
    }"
    (click)="refreshPage()"
  >
    <ng-container *ngIf="recentQueueData.servingTokenNumber">
      <div
        *ngIf="rollUpAnimation.showTokenNumber"
        [@classicTokenAnimation]="animateClassicFlag"
        (@classicTokenAnimation.done)="animateClassicDone($event)"
        @rollUpAnimation
        (@rollUpAnimation.done)="animateRollUpDone($event)"
        class="roll-up-token-number"
      >
        {{ recentQueueData.servingTokenNumber }}
      </div>
      <div
        *ngIf="rollUpAnimation.showCounter"
        @rollUpAnimation
        class="roll-up-counter"
        [ngClass]="{
          '--gt-6-characters': recentQueueData.currentCounterName.length > 6,
          '--gt-9-characters': recentQueueData.currentCounterName.length > 9
        }"
      >
        {{ recentQueueData.currentCounterName }}
      </div>
    </ng-container>

    <div class="idle-logo-container" *ngIf="!recentQueueData.servingTokenNumber">
      <img src="{{ recentQueueData.logo }}" />
    </div>
  </div>
</ng-container>
