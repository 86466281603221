function getDuration(dateTime: string) {
  if (!dateTime) return 0;

  const now = new Date().getTime();
  const time = new Date(dateTime).getTime();
  return Math.abs(now - time);
}

export const getDurationInSeconds = (dateTime: string) => {
  return Math.round(getDuration(dateTime) / 1000);
};

export function getDurationInMinutes(dateTime: string) {
  return Math.round(getDuration(dateTime) / 1000 / 60);
}
