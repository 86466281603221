<div class="bg-wrapper">
	<feedback-stand-side *ngIf="feedbackStandData" [feedbackStandData]="feedbackStandData"></feedback-stand-side>

	<question-mood *ngIf="moodSelected" [question_mood_data]="questions" [moodSelected]="moodSelected" [moodType]="currentMood" [parentComp]="this"></question-mood>

	<div class="feedback-moods" *ngIf="moods" [ngClass]="moodSelected ? 'hidden' : ''">
		<div *ngFor="let singleMood of moods">
			<div class="{{setDynamicClass(singleMood)}} feedback" (click)="setFeedbackQuestions(singleMood.type)">
				<div class="logo">
					<img src="{{moodLogo(singleMood)}}">
				</div>
				<span>{{singleMood.moodName}}</span>
			</div>
		</div>
	</div>
</div>


