import { Component, OnInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, style, transition, animate, keyframes } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { interval, timer } from 'rxjs';

import { DataService, GlobalVariables, BranchEndpointService, MainPageEndpointsService } from '../common/index';
import { AuthButtonService } from '../auth-button/index';
import { NewTokenData, PusherService } from 'app/common/_services/pusher.service';
import { QueueService } from '../common/_services/queue.service';
import { Subscription } from 'rxjs';
import { getErrorMessage } from '../common/_utils/api.util';
import { getDurationInMinutes } from '../common/_utils/time.util';

declare var swal: any;

interface SingleQueueInfoCustomization {
  branchQueueLabe: string;
  tokenLabe: string;
  branchQueueLabelFontColo: string;
  tokenLabelFontColo: string;
  backgroundColor: string;
  labelFontFamil: string;
  numberFontFamil: string;
}

const INITIAL_PEOPLE_IN_QUEUE = '00';

// ! If more view types are added, should probably refactor into multiple components like printers in master_http
@Component({
  selector: 'single-queue-info-screen',
  templateUrl: './single-queue-info-screen.component.html',
  styleUrls: ['./single-queue-info-screen.component.scss'],
  providers: [MainPageEndpointsService],
  animations: [
    trigger('classicTokenAnimation', [
      transition(
        '* => active',
        animate(
          4000,
          keyframes([
            style({ opacity: '0.0' }),
            style({ opacity: '1.0' }),
            style({ opacity: '0.0' }),
            style({ opacity: '1.0' }),
            style({ opacity: '0.0' }),
            style({ opacity: '1.0' }),
          ])
        )
      ),
    ]),
  ],
})
export class SingleQueueInfoScreenComponent implements OnInit {
  loggedIn: string;
  totalPeopleInQueue = INITIAL_PEOPLE_IN_QUEUE;
  waitingTime: number;
  customization: SingleQueueInfoCustomization;
  loading = true;
  background = '#ffffff';
  nextPendingTokenCreatedAt: string;

  timer: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService,
    private authButtonService: AuthButtonService,
    private zone: NgZone,
    private pusherService: PusherService,
    private branchService: BranchEndpointService,
    private http: HttpClient,
    private mainPageEndpointsService: MainPageEndpointsService,
    private queueService: QueueService
  ) {
    this.validateLogin();
  }

  ngOnInit() {
    if (this.loggedIn) {
      this.initPusherSubscription();
      this.loadData();

      this.zone.run(() => {
        console.log('enabled page re-render');
      });
    }
  }

  ngOnDestroy() {
    if (this.timer) this.timer.unsubscribe();
    this.timer = null;
  }

  initPusherSubscription() {
    const branchId = this.route.snapshot.params['branchId'];
    this.pusherService.connectTokenBroadcast(branchId, this.handleNewTokenData);
  }

  handleNewTokenData = (data: NewTokenData) => {
    const selectedQueueId = this.route.snapshot.params['branchQueueId'];
    const { branchQueueId, status, totalPeopleInQueue, nextPendingTokenCreatedAt } = data.data.attributes;

    if (branchQueueId == selectedQueueId) {
      this.nextPendingTokenCreatedAt = nextPendingTokenCreatedAt;
      this.setTotalPeopleInQueue(totalPeopleInQueue);
      this.setWaitingTime();

      if (this.totalPeopleInQueue === '01' && status === 'pending') {
        this.branchService.playTokenEventSound(this.branchService.soundEffects.newToken);
      }
    }
  };

  validateLogin() {
    this.loggedIn = this.dataService.sessionData();
    if (!this.loggedIn) this.router.navigate(['/login']);
    this.authButtonService.login();
  }

  loadData() {
    const branchId = this.route.snapshot.params['branchId'];
    const url = `${GlobalVariables.BASE_API_URL}/api/v1/branches/${branchId}/queue_information_customization`;

    this.http.get<any>(url).subscribe(
      ({ data }) => {
        this.customization = data.attributes;
        this.setBackgroundStyle();
      },
      (error) => {
        swal({
          text: getErrorMessage(error),
          type: 'warning',
          allowOutsideClick: false,
          showCancelButton: false,
          allowEscapeKey: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Refresh the page!',
        }).then(() => {
          if (error?.[0]?.status == '401') {
            this.dataService.clearData();
            this.router.navigate(['login']);
          } else {
            location.reload();
          }
        });
      }
    );

    const branchQueueId = this.route.snapshot.params['branchQueueId'];

    this.queueService.getQueue(branchQueueId).subscribe(({ data }) => {
      const { nextPendingToken } = data.attributes;

      if (nextPendingToken) {
        this.nextPendingTokenCreatedAt = nextPendingToken.created_at;
        this.setWaitingTime();
      }
    });

    this.loadTotalPeopleInQueue();
  }

  loadTotalPeopleInQueue() {
    const branchQueueId = this.route.snapshot.params['branchQueueId'];

    this.mainPageEndpointsService.getQueuesData().subscribe(({ data }) => {
      const branchQueue = data.find((it) => it.id == branchQueueId);

      if (branchQueue) {
        const { peopleInQueue } = branchQueue.attributes;
        this.setTotalPeopleInQueue(peopleInQueue);
      }

      this.loading = false;
    });
  }

  setTotalPeopleInQueue(totalPeopleInQueue: number) {
    const isSingleDigit = `${totalPeopleInQueue}`.length === 1;
    this.totalPeopleInQueue = `${isSingleDigit ? 0 : ''}${totalPeopleInQueue}`;
  }

  setWaitingTime() {
    if (!this.nextPendingTokenCreatedAt) return (this.waitingTime = 0);
    console.log(
      'setting wt',
      this.nextPendingTokenCreatedAt,
      getDurationInMinutes(this.nextPendingTokenCreatedAt)
    );
    console.trace();
    console.log('______');
    this.waitingTime = getDurationInMinutes(this.nextPendingTokenCreatedAt);
  }

  refreshPage() {
    location.reload();
  }

  setBackgroundStyle() {
    const bgColor = this.customization ? this.customization.backgroundColor : 'white';
    this.background = `linear-gradient(144deg, ${bgColor}, #e0e0e085)`;
  }

  startTimer() {
    this.timer?.unsubscribe();

    const now = Date.now();
    const delay = (60 - ((now / 1000) % 60)) * 1000;
    const interval = 1000 * 60;

    this.timer = timer(delay, interval).subscribe(() => {
      console.log('timer tick');
      if (this.totalPeopleInQueue !== '00') this.setWaitingTime();
    });
  }

  /**
   *  Commented out because current implementation of priority data is not being used yet.
   *  [TODO] Re-evaluate if needed when priority is revisited.
   */
  // startPriorityDataFetchTimer() {
  //   const fetchStartTime = Date.now();
  //   const branchId = this.route.snapshot.params['branchId'];
  //   const branchQueueId = this.route.snapshot.params['branchQueueId'];

  //   const url = `${GlobalVariables.BASE_API_URL}/api/v1/branches/${branchId}/branch_queues/${branchQueueId}/priority_data`;

  //   this.http.get<any>(url).subscribe(
  //     ({ data }) => {
  //       const { nextPendingTokenIncludingPriority, peopleInQueueIncludingPriority } = data?.attributes || {};

  //       this.nextPendingTokenCreatedAt = nextPendingTokenIncludingPriority?.created_at;
  //       this.setWaitingTime();
  //       this.setTotalPeopleInQueue(peopleInQueueIncludingPriority);
  //       this.scheduleNextPriorityDataFetch(fetchStartTime);
  //     },
  //     (error) => {
  //       console.error(error);
  //       this.scheduleNextPriorityDataFetch(fetchStartTime);
  //     }
  //   );
  // }

  // scheduleNextPriorityDataFetch(startFetchTime: number) {
  //   const currentTime = Date.now();
  //   const timeSinceLastFetchStart = currentTime - startFetchTime;
  //   const timeout = Math.max(0, 5 * 60 * 1000 - timeSinceLastFetchStart);
  //   setTimeout(() => this.startPriorityDataFetchTimer(), timeout);
  //   this.startTimer();
  // }
}
