import { BranchQueueMonitorOverview } from './branch-queue-monitor-overview';

export class MonitorOverview {
  DEFAULT_FONT_FAMILY = 'Roboto';
  DEFAULT_FONT_COLOR = '#fff';
  DEFAULT_BACKGROUND_COLOR = '#384148';
  DEFAULT_FONT_SIZE = 43;
  DEFAULT_BORDER_COLOR = '#1f2343';

  public id;
  public name;
  public queues: Array<BranchQueueMonitorOverview>;
  public value;
  public label;
  public fontFamily;
  public fontFileUrl;
  public counters;
  public playTokenFetchSound = false;
  public showCounterName = false;

  constructor() {
    this.queues = new Array<BranchQueueMonitorOverview>();
  }

  setMonitorOverviewData(data) {
    this.id = data.id;
    this.name = data.attributes.name;
    this.value = this.id;
    this.label = this.name;
    this.setBranchQueuesData(data);
    this.setFontStyles(data);
    this.playTokenFetchSound = data.attributes.playTokenFetchSound;
    this.showCounterName = data.attributes.showCounterName;
  }

  setFontStyles(data) {
    this.fontFamily = data.attributes.fontFamily || this.DEFAULT_FONT_FAMILY;
    this.fontFileUrl = data.attributes.fontFileUrl;
  }

  setBranchQueuesData(data) {
    const queues = data.attributes.queues.data;
    const branchQueueMonitorOverviews = [];

    queues.forEach((it) => {
      let queue = new BranchQueueMonitorOverview();
      queue.setBranchQueueMonitorOverviewData(it);
      branchQueueMonitorOverviews.push(queue);
    });

    this.queues = branchQueueMonitorOverviews;
    this.counters = branchQueueMonitorOverviews;
  }

  setAnimationFlag(data) {
    if (data.attributes.activeQueueId) {
      let activeQueue = this.queues.filter((x) => x.branchQueueId == data.attributes.activeQueueId)[0];
      if (activeQueue) {
        return activeQueue;
      }
    }
    return null;
  }
}

export class MonitorOverviewAnimationData {
  public id;
  public date;
  public status;

  constructor(data = null) {
    if (data) {
      this.id = data.id;
      this.status = data.animationFlag;
    } else {
      this.status = false;
    }
    this.date = new Date();
  }

  setAnimationData(data) {
    this.id = data.id;
    this.status = true;
    this.date = new Date();
  }
}
