import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'twentyFourToZero'
})

export class TwentyFourToZero implements PipeTransform {
  transform(value: string, showOneZero: string) {
    if(value.slice(0, 2) === '24') {
      return (showOneZero ? '0' : '00') + value.substr(2,6)
    }
    else return value;
  }
}
