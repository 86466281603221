<div *ngIf="loading" class="loader"></div>
<div
  *ngIf="!loading"
  class="single-queue-info-screen"
  [ngStyle]="{
    background: background
  }"
  (click)="refreshPage()"
>
  <div class="single-queue-info-screen-left-block">
    <span
      class="single-queue-info-screen-label"
      [ngStyle]="{
        fontFamily: customization?.labelFontFamily
      }"
    >
      {{ customization?.peopleInQueueLabel || 'PIQ' }}
    </span>
    <span
      class="single-queue-info-screen-number"
      [ngStyle]="{
        color: customization?.peopleInQueueLabelFontColor,
        fontFamily: customization?.timeFontFamily
      }"
    >
      {{ totalPeopleInQueue }}
    </span>
  </div>
  <div class="single-queue-info-screen-right-block">
    <span
      class="single-queue-info-screen-label"
      [ngStyle]="{
        fontFamily: customization?.labelFontFamily
      }"
    >
      {{ customization?.waitingTimeLabel || 'WT' }}
    </span>
    <span
      class="single-queue-info-screen-number"
      [ngStyle]="{
        color: customization?.waitingTimeLabelFontColor,
        fontFamily: customization?.timeFontFamily
      }"
    >
      {{ waitingTime || 0 }}
    </span>
  </div>
</div>
