import { LoaderComponent } from './common/_components/loader/loader.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [CommonModule],
  declarations: [LoaderComponent],
  exports: [CommonModule, LoaderComponent],
})
export class SharedModule {}
