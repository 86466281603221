import { Input, Component, OnInit, ViewChild } from '@angular/core';

import { MoodEnum, FeedbackStandEndpointsService, DataService, GlobalVariables } from 'app/common';
import { Router } from '@angular/router';

declare var swal: any;

@Component({
  selector: 'question-mood',
  templateUrl: './question-mood.component.html',
  styleUrls: ['./question-mood.component.scss'],
  providers: [FeedbackStandEndpointsService],
})
export class QuestionMoodComponent implements OnInit {
  @Input('moodType') currentMood;
  @Input('question_mood_data') questions;
  @Input('moodSelected') moodSelected;
  @Input('parentComp') parentComp;
  @ViewChild('textarea') textAreaEl;
  formData: any = {};
  currentQuestion;
  index = 0;
  questionSet;
  questionsPresent;
  questionLength = 1;
  formSubmitted = false;
  numbers;
  showTextArea = false;
  inactiveTimeout = null;

  constructor(
    private router: Router,
    private feedbackService: FeedbackStandEndpointsService,
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.setData();
    this.formData.answers_feedbacks_attributes = [];
  }

  setData() {
    if (this.currentMood.questions.length > 0) {
      this.questionSet = this.questions;
      this.questionsPresent = true;

      this.currentQuestion = this.questions[this.index];
      let question = this.currentQuestion;

      this.setInactiveTimeout(question, 10000);

      this.questionLength += this.currentMood.questions.length;
      this.numbers = Array(this.questionLength)
        .fill(0)
        .map((x, i) => i);
    } else {
      this.questionsPresent = false;
      this.submitForm();
    }
  }

  answerSelected(answer) {
    answer = String(answer);

    const feedbackData: any = { answer_id: answer };
    if (answer === '-1') {
      feedbackData.comment = this.textAreaEl.nativeElement.value; // -1 === 'Other/Annet' answer
      feedbackData.question_id = this.currentQuestion.id;
    }

    this.formData.answers_feedbacks_attributes.push(feedbackData);
    this.index++;

    if (this.index < this.questions.length) {
      this.currentQuestion = this.questions[this.index];
      let question = this.currentQuestion;
      this.showTextArea = false;

      this.setInactiveTimeout(question, 10000);
    } else {
      this.questionSet = '';
      this.currentQuestion = '';
      this.questionsPresent = false;
      if (this.inactiveTimeout) clearTimeout(this.inactiveTimeout);
      this.submitForm();
    }
  }

  displayTextArea() {
    this.showTextArea = true;
    setTimeout(() => this.textAreaEl.nativeElement.focus(), 200);

    this.currentQuestion = this.questions[this.index];
    let question = this.currentQuestion;
    this.setInactiveTimeout(question, 12000);
  }

  onTextareaChange() {
    this.currentQuestion = this.questions[this.index];
    let question = this.currentQuestion;
    this.setInactiveTimeout(question, 12000);
  }

  setColor() {
    if (this.currentMood.type == MoodEnum['excellent']) {
      return 'excellent-button';
    } else if (this.currentMood.type == MoodEnum['good']) {
      return 'good-button';
    } else if (this.currentMood.type == MoodEnum['indifferent']) {
      return 'indifferent-button';
    } else if (this.currentMood.type == MoodEnum['bad']) {
      return 'bad-button';
    }
  }

  setDynamicClass() {
    if (this.currentMood.type == MoodEnum['excellent']) {
      return 'row-excellent';
    } else if (this.currentMood.type == MoodEnum['good']) {
      return 'row-good';
    } else if (this.currentMood.type == MoodEnum['indifferent']) {
      return 'row-indifferent';
    } else if (this.currentMood.type == MoodEnum['bad']) {
      return 'row-bad';
    }
  }

  moodLogo() {
    if (this.currentMood.type == MoodEnum['excellent']) {
      return '/assets/images/excellent_smiley.png';
    } else if (this.currentMood.type == MoodEnum['good']) {
      return '/assets/images/good_smiley.svg';
    } else if (this.currentMood.type == MoodEnum['indifferent']) {
      return '/assets/images/indifferent_smiley.png';
    } else if (this.currentMood.type == MoodEnum['bad']) {
      return '/assets/images/bad_smiley.png';
    }
  }

  setMoodRating() {
    switch (this.currentMood.type) {
      case 0: {
        return 4;
      }
      case 1: {
        return 3;
      }
      case 2: {
        return 2;
      }
      case 3: {
        return 1;
      }
    }
  }

  errorMessage(error) {
    let message;

    if (error[0] && error[0].status == '404') {
      message = GlobalVariables.ERROR_MESSAGE_404;
    } else if (error[0] && error[0].message) {
      message = error[0].message;
    } else {
      message = GlobalVariables.DEFAULT_ERROR_MESSAGE;
    }

    return message;
  }

  setInactiveTimeout(question, timeout: number) {
    if (this.inactiveTimeout) clearTimeout(this.inactiveTimeout);

    this.inactiveTimeout = setTimeout(() => {
      if (question == this.currentQuestion) {
        // Submit data if timed out, push other text if on other text screen
        if (this.showTextArea) {
          const feedbackData: any = { answer_id: -1 };
          feedbackData.comment = this.textAreaEl.nativeElement.value; // -1 === 'Other/Annet' answer
          feedbackData.question_id = this.currentQuestion.id;
          this.formData.answers_feedbacks_attributes.push(feedbackData);
        }

        this.questionSet = '';
        this.currentQuestion = '';
        this.questionsPresent = false;
        this.submitForm();

        this.parentComp.moodSelected = false;
        this.showTextArea = false;
      }
    }, timeout);
  }

  refresh() {
    location.reload();
  }

  submitForm() {
    this.formSubmitted = true;
    this.showTextArea = false;
    this.formData.rating = this.setMoodRating();
    this.formData.branch_id = this.dataService.branchData();
    this.feedbackService.generateFeedback(this.formData).subscribe(
      (data: any) => {
        setTimeout(() => {
          this.parentComp.moodSelected = false;
          this.showTextArea = false;
        }, 1500);
      },
      (error) => {
        swal({
          text: this.errorMessage(error),
          type: 'warning',
          allowOutsideClick: false,
          showCancelButton: false,
          allowEscapeKey: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Refresh the page!',
        }).then(() => {
          if (error && error[0].status == '401') {
            this.dataService.clearData();
            this.router.navigate(['login']);
          } else {
            location.reload();
          }
        });
      }
    );
  }
}
