import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'readableTime',
})
export class ReadableTyimePipe implements PipeTransform {
  constructor() {}

  transform(value) {
    const minutes = Math.floor(parseInt(value) / 60);

    if (minutes > 60) {
      const hours = Math.floor(minutes / 60);
      return `${hours} hrs`;
    }

    return `${minutes} min`;
  }
}
