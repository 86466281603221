import { Component, OnInit } from '@angular/core';

import { AuthButtonService } from './auth-button.service'

@Component({
  selector: 'auth-button',
  templateUrl: './auth-button.component.html'
})
export class AuthButtonComponent implements OnInit {
  buttonText;
  url;

  constructor(private authButtonService: AuthButtonService) { }

  ngOnInit() {
    this.authButtonService.getMessage().subscribe(
      sub => {
        if (sub) {
          this.buttonText = sub.buttonText;
          this.url = sub.url;
        }
        else {
          let logger = localStorage.getItem('currentUser')
          if(logger) {
            this.buttonText = 'Logout'
            this.url = '/logout'
          } else {
            this.buttonText = 'Login'
            this.url = '/login'
          }
        }
      }
    );
  }

}
