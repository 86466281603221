import { Component } from '@angular/core';
import { MonitorComponent } from '../../monitor.component';
import { MonitorEndpointsService } from '../../../common';

@Component({
  selector: 'app-monitor-vrt',
  templateUrl: './monitor-vrt.component.html',
  styleUrls: ['./monitor-vrt.component.scss'],
  providers: [MonitorEndpointsService],
})
export class MonitorVrtComponent extends MonitorComponent {}
