// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import awsAmplifyEnv from './aws-amplify-env';

export const environment = {
  production: false,
  apiUrl: 'https://staging-api.qtimeportal.com',
  apiCableUrl: 'wss://staging-api.qtimeportal.com',
  qtimeQrUrl: 'https://staging.d9kzc728rzjuc.amplifyapp.com',
  soundUrls: {
    newPendingToken: 'https://qtimestaging.s3.eu-north-1.amazonaws.com/sound/newPendingToken.mp3',
    nextToken: 'https://qtimestaging.s3.eu-north-1.amazonaws.com/sound/nextToken.mp3',
  },
  pusher: {
    app_key: '9a2ffdbff0b63571f3af',
    app_cluster: 'eu',
  },
  sentry: {
    // safe to keep public according to docs due: https://docs.sentry.io/product/sentry-basics/dsn-explainer/
    dsn: 'https://970bb75a31d240b298ba8360116d70d6@o4504672781008896.ingest.sentry.io/4504672783433728',
  },
  qtimeAppPrinterUrl: 'http://3.123.0.50/',
  ...awsAmplifyEnv,
};
