import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { trigger, style, transition, animate, keyframes } from '@angular/animations';

import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';

import {
  DataService,
  MonitorOverviewEndpointsService,
  GlobalVariables,
  MonitorOverview,
  MonitorOverviewAnimationData,
  BranchEndpointService,
} from '../common/index';
import { AuthButtonService } from '../auth-button/index';
import { NewTokenData, PusherService } from '../common/_services/pusher.service';

declare var swal: any;

@Component({
  selector: 'monitor-overview',
  templateUrl: './monitor_overview.component.html',
  styleUrls: ['./monitor_overview.component.scss'],
  providers: [MonitorOverviewEndpointsService],
  animations: [
    trigger('tokenUpdate', [
      transition(
        '* => 1',
        animate(
          4000,
          keyframes([
            style({ opacity: '0.5' }),
            style({ opacity: '1.0' }),
            style({ opacity: '0.5' }),
            style({ opacity: '1.0' }),
            style({ opacity: '0.5' }),
            style({ opacity: '1.0' }),
          ])
        )
      ),
    ]),
  ],
})
export class MonitorOverviewComponent implements OnInit, OnDestroy {
  monitorOverviewData;
  counters;
  queues;
  routeId;
  sound;
  loggedIn;
  actionCable;
  animationData: { [key: string]: MonitorOverviewAnimationData } = {};
  unSubscribeCable;
  unSubscribeCable2;
  sliderId;
  iframeSrc;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService,
    private authButtonService: AuthButtonService,
    private monitorOverviewEndpointsService: MonitorOverviewEndpointsService,
    private zone: NgZone,
    private sanitizer: DomSanitizer,
    private pusherService: PusherService,
    private branchService: BranchEndpointService
  ) {
    this.validateLogin();
    this.monitorOverviewData = new MonitorOverview();
  }

  ngOnInit() {
    if (this.loggedIn) {
      this.routeParams();
      this.loadMonitorOverview();
      this.initPusherSubscription();

      this.zone.run(() => {
        console.log('enabled page re-render');
      });
    }
  }

  ngOnDestroy() {
    this.monitorOverviewData = null;
    this.counters = null;
    this.queues = null;
    this.routeId = null;
    this.loggedIn = null;
    this.animationData = null;
    this.sliderId = null;
    this.iframeSrc = null;
  }

  initPusherSubscription() {
    const branchId = this.route.snapshot.params['branchId'];
    this.pusherService.connectTokenBroadcast(branchId, this.handleNewTokenData);
  }

  handleNewTokenData = (payload: NewTokenData) => {
    if (!payload) return;

    const { playTokenFetchSound } = this.monitorOverviewData;
    const { branchQueueId, status, tokenNumber, totalPeopleInQueue, counterName } = payload.data.attributes;

    const branchQueue = this.queues.find((it) => it.branchQueueId == branchQueueId);

    if (!branchQueue) return;

    switch (status) {
      case 'pending':
        branchQueue.peopleInQueue = totalPeopleInQueue;
        if (playTokenFetchSound && totalPeopleInQueue === 1) {
          this.branchService.playTokenEventSound(this.branchService.soundEffects.newToken);
        }
        break;

      case 'serving':
        branchQueue.peopleInQueue -= 1;
        branchQueue.currentTokenNumber = tokenNumber;
        branchQueue.counterName = counterName;
        this.loadAnimationData(branchQueueId);
        this.branchService.playTokenEventSound(null, tokenNumber);
        break;

      default:
        break;
    }
  };

  validateLogin() {
    this.loggedIn = this.dataService.sessionData();
    if (!this.loggedIn) this.router.navigate(['/login']);
    this.authButtonService.login();
  }

  routeParams() {
    this.route.params.subscribe((params) => {
      this.routeId = +params['id']; // (+) converts string 'id' to a number
    });
  }

  loadAnimationData(activeQueueId) {
    for (var i = 0; i < this.queues.length; i++) {
      let queue = this.queues[i];
      queue.animationFlag = queue.branchQueueId == activeQueueId;
      let data = new MonitorOverviewAnimationData(queue);
      this.animationData[queue.id] = data;
    }
  }

  getTimeDifference(startDate) {
    if (startDate) {
      let endDate = new Date();
      let diff = (endDate.getTime() - startDate.getTime()) / 1000;
      return diff;
    }
    return 0;
  }

  startBackgroundAnimationService() {
    setInterval(() => {
      if (this.animationData) {
        for (var i = 0; i < this.queues.length; i++) {
          let instance = this.animationData[this.queues[i].id];

          if (this.getTimeDifference(instance.date) >= 4) {
            this.animationData[this.queues[i].id].status = false;
          }
        }
      }
    }, 1000);
  }

  getBackground(queue) {
    return 'linear-gradient(' + queue.backgroundColor1 + ',' + queue.backgroundColor2 + ')';
  }

  loadMonitorOverview() {
    this.monitorOverviewEndpointsService.getMonitorOverviewData(this.routeId).subscribe(
      (data: any) => {
        this.monitorOverviewData = new MonitorOverview();
        this.monitorOverviewData.setMonitorOverviewData(data.data);
        this.queues = this.monitorOverviewData.queues;
        this.loadAnimationData(data.data.attributes.activeQueueId);
        this.startBackgroundAnimationService();
      },
      (error) => {
        swal({
          text: this.errorMessage(error),
          type: 'warning',
          allowOutsideClick: false,
          showCancelButton: false,
          allowEscapeKey: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Refresh the page!',
        }).then(() => {
          if (error && error[0].status == '401') {
            this.dataService.clearData();
            this.router.navigate(['login']);
          } else {
            location.reload();
          }
        });
      }
    );
  }

  errorMessage(error) {
    let message;

    if (error[0] && error[0].status == '404') {
      message = GlobalVariables.ERROR_MESSAGE_404;
    } else if (error[0] && error[0].message) {
      message = error[0].message;
    } else {
      message = GlobalVariables.DEFAULT_ERROR_MESSAGE;
    }

    return message;
  }
}
