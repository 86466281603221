import { Injectable } from '@angular/core';
import Pusher, { Channel } from 'pusher-js';
import { GlobalVariables } from '../global';

export interface NewTokenData {
  data: {
    attributes: {
      counterId?: number;
      counterName: string;
      branchQueueId: number;
      branchQueueName: string;
      createdAt: string;
      nextPendingTokenNumber: number;
      nextPendingTokenCreatedAt: string;
      originType?: string;
      status: 'serving' | 'pending' | 'no_show' | 'served';
      tokenNumber: string;
      totalPeopleInQueue: number;
      startTime?: string;
    };

    id: string;
    type: string;
  };
}

interface ChannelConnection {
  channel: Channel;
  event: string;
}

@Injectable()
export class PusherService {
  private pusher: Pusher;
  private channels: Channel[] = [];
  public tokenBroadcastChannel: Channel;

  constructor() {
    const { app_cluster, app_key } = GlobalVariables.PUSHER_CONFIG;
    this.pusher = new Pusher(app_key, { cluster: app_cluster });
  }

  connectTokenBroadcast(branchId: number, newDataHandler: Function): boolean {
    this.disconnectAllChannels();

    try {
      this.tokenBroadcastChannel = this.pusher.subscribe(
        `token-broadcast-${branchId}`
      );
    } catch (error) {
      console.error('some error');
      return false;
    }

    this.channels.push(this.tokenBroadcastChannel);
    this.tokenBroadcastChannel.bind('token-sync', newDataHandler);
    return true;
  }

  disconnectAllChannels() {
    this.channels.forEach((it) => {
      it && it.unbind_all();
      it && it.unsubscribe();
      it && it.disconnect();
    });
    this.tokenBroadcastChannel = null;
  }
}
