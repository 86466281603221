import { Token } from './token';
import { Mood } from './mood';

export class BranchQueueOverview {
    public id;
    public name;
    public branchQueueId;
    public tokens: Array<Token>;
    public mood: Mood;

    setBranchQueueOverviewData(data, mood) {
      this.id = data.id;
      this.name = data.attributes.name;
      this.branchQueueId = data.attributes.branchQueueId;
      this.tokens = new Array<Token>();
      this.mood = mood;
      this.setTokensData(this.tokens, data);

      return [this.id, this.name, this.tokens];
    }

    setTokensData(tokens, data) {
      for(var i = 0; i < data.attributes.tokens.data.length; i++) {
        if (data.attributes.tokens.data[i]) {
          let token = new Token;
          token.setTokenData(data.attributes.tokens.data[i]);
          token.setMood(this.mood);

          tokens.push(token);
        }
      }
    }
  }
