<div class="wrapper">
	<div class="{{setDynamicClass()}}">
		<div *ngIf="!formSubmitted">
			<div class="mood-header">
				<img src="{{moodLogo()}}">
				<span class="mood-name">{{currentMood.moodName}}</span>
			</div>

			<div *ngIf="questionSet && !showTextArea">
				<div *ngIf="currentQuestion" class="question-text">
					<div class="question-div"><span class="single-question">{{currentQuestion.questionText}}</span></div>
					<div *ngFor="let answer of currentQuestion.answers">
						<div class="{{setColor()}} answers" (click)="answerSelected(answer.id)">{{answer.answerText}}</div>
					</div>
					<div class="{{setColor()}} answers" (click)="displayTextArea()">Annet</div>
				</div>
			</div>

			<div *ngIf="showTextArea">
				<div class="question-text">
					<div class="question-div"><span class="single-question">{{currentQuestion.questionText}}</span></div>
					<textarea #textarea (keyup)="onTextareaChange()"></textarea>
					<div class="{{setColor()}} answers" (click)="answerSelected(-1)"> SEND INN </div>
				</div>
			</div>


			<div class="question-indicator">
				<div *ngFor="let question of currentMood.questions; let i = index">
					<div class="{{setColor()}} question-dot" [ngClass]="{'active': i <= index}"></div>
				</div>
			</div>
		</div>

		<div *ngIf="formSubmitted" class="thank-you-note" (click)="refresh()">
			<div class="thank-you">Tusen takk</div>
			<div>Ha en fin dag 😊!</div>
		</div>
	</div>
</div>
