import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { timer } from 'rxjs';

import {
  MoodyEnum,
  DataService,
  QueueOverviewEndpointsService,
  GlobalVariables,
  QueueOverview,
  BranchEndpointService,
} from '../common/index';
import { AuthButtonService } from '../auth-button/index';
import { NewTokenData, PusherService } from 'app/common/_services/pusher.service';
import { calcWaitingTime, getMoodByWaitTime } from 'app/common/_utils/mood.util';
import { animate, style, transition, trigger } from '@angular/animations';

declare var swal: any;

export const tokenAnimation = trigger('tokenAnimation', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateX(-200px)' }),
    animate('400ms cubic-bezier(0.34, 1.56, 0.64, 1)', style({ opacity: 1, transform: 'translateX(0)' })),
  ]),
  transition(':leave', [
    style({ opacity: 1, transform: 'translateX(0)', height: '*' }),
    animate('400ms cubic-bezier(0.34, 1.56, 0.64, 1)', style({ transform: 'translateX(25%)', opacity: 0 })),
    animate('400ms cubic-bezier(0.34, 1.56, 0.64, 1)', style({ height: 0 })),
  ]),
]);

@Component({
  selector: 'queue-overview',
  templateUrl: './queue-overview.component.html',
  styleUrls: ['./queue-overview.component.scss'],
  providers: [QueueOverviewEndpointsService],
  animations: [tokenAnimation],
})
export class QueueOverviewComponent implements OnInit, OnDestroy {
  queueOverviewData;
  tokensLoaded = false;
  queues = [];
  dateToday = new Date();
  routeId;
  loggedIn;
  branchData;
  waitTimesSub;
  timer;
  mutedQueues = []; // list of id, of muted queues
  sound;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService,
    private authButtonService: AuthButtonService,
    private queueOverviewEndpointsService: QueueOverviewEndpointsService,
    private zone: NgZone,
    private sanitizer: DomSanitizer,
    private pusherService: PusherService,
    private branchService: BranchEndpointService
  ) {
    this.validateLogin();
    this.queueOverviewData = new QueueOverview();
  }

  ngOnInit() {
    if (this.loggedIn) {
      this.routeParams();
      this.loadBranch();
      this.initPusherSubscription();

      this.tokensLoaded = true;

      this.zone.run(() => {
        console.log('enabled page re-render');
      });
    }
  }

  ngOnDestroy() {
    this.queueOverviewData = null;
    this.queues = null;
    this.routeId = null;
    this.loggedIn = null;
    this.tokensLoaded = null;
    this.branchData = null;
    if (this.waitTimesSub) this.waitTimesSub.unsubscribe();
    this.waitTimesSub = null;
    clearInterval(this.timer);
    this.timer = null;
    this.dateToday = null;
  }

  initPusherSubscription() {
    const branchId = this.route.snapshot.params['branchId'];
    this.pusherService.connectTokenBroadcast(branchId, this.handleNewTokenData);
  }

  handleNewTokenData = (data: NewTokenData) => {
    const { id, attributes } = data.data;
    const { branchQueueId, createdAt, status, tokenNumber, originType, totalPeopleInQueue } = attributes;

    const branchQueue = this.queues.find((it) => it.branchQueueId === branchQueueId);
    if (!branchQueue) return;

    const { mood } = branchQueue;
    const queueIsMuted = this.mutedQueues.includes(branchQueue.id);

    if (status === 'pending') {
      const waitTime = Math.floor(calcWaitingTime(createdAt) / 60);

      const newToken = {
        id,
        createdAt,
        originType,
        status,
        tokenNumber,
        waitTime,
        mood: getMoodByWaitTime(waitTime, mood),
      };

      if (!queueIsMuted && totalPeopleInQueue === 1) {
        this.branchService.playTokenEventSound(this.branchService.soundEffects.newToken);
      }

      branchQueue.tokens.push(newToken);
    } else if (['no_show', 'served', 'serving'].includes(status)) {
      const index = branchQueue.tokens.map((it) => it.id).indexOf(id);

      if (index > -1) {
        branchQueue.tokens.splice(index, 1);
      }
    }
  };

  validateLogin() {
    this.loggedIn = this.dataService.sessionData();

    if (!this.loggedIn) {
      this.router.navigate(['/login']);
    }

    this.authButtonService.login();
  }

  routeParams() {
    this.route.params.subscribe((params) => {
      this.routeId = +params['id']; // (+) converts string 'id' to a number
    });
  }

  loadBranch() {
    this.queueOverviewEndpointsService.getBranchData().subscribe(
      (data: any) => {
        this.branchData = data.data;
        this.loadQueueOverview();
      },
      (error) => {
        swal({
          text: this.errorMessage(error),
          type: 'warning',
          allowOutsideClick: false,
          showCancelButton: false,
          allowEscapeKey: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Refresh the page!',
        }).then(() => {
          if (error && error[0].status == '401') {
            this.dataService.clearData();
            this.router.navigate(['login']);
          } else {
            location.reload();
          }
        });
      }
    );
  }

  loadQueueOverview() {
    this.queueOverviewEndpointsService.getQueueOverviewData(this.routeId).subscribe(
      (data: any) => {
        this.queueOverviewData = new QueueOverview();
        this.queueOverviewData.mood.setMoodData(this.branchData);
        this.queueOverviewData.setQueueOverviewData(data.data);
        this.queues = this.queueOverviewData.queues;
        this.mutedQueues = [];
        this.muteQueuesFromURL(); //mutes queues from URL query params
        this.waitingTimesForToken();

        console.log(this.queueOverviewData);
      },
      (error) => {
        swal({
          text: this.errorMessage(error),
          type: 'warning',
          allowOutsideClick: false,
          showCancelButton: false,
          allowEscapeKey: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Refresh the page!',
        }).then(() => {
          if (error && error[0].status == '401') {
            this.dataService.clearData();
            this.router.navigate(['login']);
          } else {
            location.reload();
          }
        });
      }
    );
  }

  waitingTimesForToken(): void {
    this.timer = timer(0, 1000 * 60);

    this.waitTimesSub = this.timer.subscribe(() => {
      this.queues.forEach((queue, index, arr) => {
        queue.tokens.forEach((token) => {
          token.waitTime += 60;
          token.mood = getMoodByWaitTime(token.waitTime, queue.mood);
        });
      });
    });
  }

  tokenMood(token): string {
    return MoodyEnum[token.mood];
  }

  muteQueue(queueId: string): void {
    //if no mute params in URL:
    //catch if undefined to preserve "?mute=" default param and not add it to mutedqueues
    if (queueId !== 'undefined') {
      const splicedQueue = this.mutedQueues.filter((id) => id !== queueId); // remove queueId

      if (splicedQueue.length !== this.mutedQueues.length) {
        this.mutedQueues = splicedQueue;
      } else this.mutedQueues.push(queueId);
    }

    /* updates URL query when mute button gets clicked
     TODO fix %2C in url when updating
     the commented code below should fix it, but it doesn't work, will look at it further:
     let muteParams: string = decodeURIComponent(this.mutedQueues.join(",")) */
    this.router.navigate([], {
      queryParams: {
        mute: this.mutedQueues,
      },
    });
  }

  muteQueuesFromURL() {
    //cast mutedParam to string, if not it will be undefined.
    //TODO might be a better way to get query params
    let mutedParam = String(this.route.snapshot.queryParams['mute']);

    //turn into array for easy mapping
    let mutedArr = mutedParam.split(',');
    mutedArr.forEach((queueID) => this.muteQueue(queueID));
  }

  errorMessage(error) {
    let message;

    if (error[0] && error[0].status == '404') {
      message = GlobalVariables.ERROR_MESSAGE_404;
    } else if (error[0] && error[0].message) {
      message = error[0].message;
    } else {
      message = GlobalVariables.DEFAULT_ERROR_MESSAGE;
    }

    return message;
  }
}
