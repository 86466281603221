import { CounterDisplayComponent } from './../counter-display/counter-display.component';
import { NewTokenData } from '../common/_services/pusher.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-name-based-counter-display',
  templateUrl: './name-based-counter-display.component.html', // copy from CounterDisplayComponent
  styleUrls: ['../counter-display/counter-display.component.scss'],
})
export class NameBasedCounterDisplayComponent extends CounterDisplayComponent {
  urlCounterName: string;

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.urlCounterName = params['counterName'].replace(/_/g, ' ');
      this.initPusherSubscription();
      this.loadData();
      this.loadCounterCustomization();
      this.setIdleScreenTimeout(false);
    });
  }

  loadData() {
    this.mainPageEndpointsService.getQueuesData(true).subscribe(
      (res) => {
        const { included } = res;

        this.counters = included
          .filter((it) => it.type === 'counters' && it.attributes.name === this.urlCounterName)
          .map(this.mapToCounterItem);

        const { showIdleScreenAfterInactivity } = this.customization;
        if (!showIdleScreenAfterInactivity) this.showIdleScreen = false;

        this.setLatestTokenNumber();
      },
      (error) => this.alertMessage(error)
    );
  }

  handleNewTokenData = (payload: NewTokenData) => {
    if (!payload) return;

    const counterIds = this.counters.map((it) => it.id);
    const hasCounterId = counterIds.includes(+payload.data.attributes.counterId);

    if (!hasCounterId) return;

    const { attributes } = payload.data;
    const { counterId, status, tokenNumber, totalPeopleInQueue } = attributes;

    if (status === 'pending' && totalPeopleInQueue === 1) {
      return this.branchService.playTokenEventSound(this.branchService.soundEffects.newToken);
    } else if (status === 'serving') {
      this.setIdleScreenTimeout();

      const counter = this.counters.find((it) => it.id === counterId);
      counter.currentTokenNumber = this.mapToTokenData(payload);
      this.latestTokenNumber = tokenNumber;

      this.triggerTokenAnimation();

      if (this.counters[0]?.nextTokenSoundEnabled) {
        this.branchService.playTokenEventSound(null, tokenNumber);
      }
    }
  };
}
