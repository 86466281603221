import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { trigger, style, transition, animate, keyframes } from '@angular/animations';
import { MoodyEnum, Token } from '../../common';

@Component({
  selector: 'app-view-board-token-list',
  templateUrl: './token-list.component.html',
  styleUrls: ['./token-list.component.scss'],
  animations: [
    trigger('tokenListPanel', [
      transition('void => *', [
        animate(
          150,
          keyframes([
            style({ opacity: 0, transform: 'translateY(-200px)', offset: 0 }),
            style({ opacity: 1, transform: 'translateY(0)', offset: 1 }),
          ])
        ),
      ]),
    ]),
    trigger('tokenRemovePanel', [
      transition('* => void', [
        animate(
          '300ms ease-out',
          style({
            opacity: 0,
            transform: 'translateX(500px) scale(1)',
          })
        ),
      ]),
    ]),
    trigger('tokenSlideUpPanel', [
      transition('0 => 1', [
        animate(
          '300ms ease-out',
          style({
            transform: 'translateY(calc(-15px - 3.8vw)) scale(1)',
          })
        ),
      ]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ViewBoardTokenListComponent implements OnInit {
  @Input() tokens: Array<Token> = [];
  tokenSliding = false;

  constructor() {}

  ngOnInit(): void {}

  tokenMood(token): string {
    return MoodyEnum[token.mood];
  }
  animTokenRemoveStart(event, token) {
    if (event?.fromState === 'void') return;

    let index = this.tokens[0];
    if (index) {
      if (parseInt(token.id) + 1 === parseInt(index.id)) {
        this.tokenSliding = true;
      }
    } else {
      this.tokenSliding = true;
    }
  }

  animTokenRemoveEnd(event) {
    if (event.fromState === 'void') return;

    setTimeout(() => {
      this.tokenSliding = false;
    }, 200);
  }
}
