<div class="counter-console-wrapper">
  <div class="counter-console-container" [@blinkAnimation]="blinkAnimationFlag">
    <div class="counter-stats">
      <div class="counter-nickname">
        <i class="edit-icon" (click)="setEditing(true)"></i>
        {{ counter?.nickname || '---' }}
      </div>
      <span class="counter-name">{{ counter?.counterName }} </span>
      <span class="counter-no" [ngClass]="{ '--not-serving': !counter?.isServing() }">
        {{ counter?.isServing() ? counter.currentlyServing.tokenNumber : '000' }}
      </span>
      <ng-container *ngIf="counter?.currentlyServing?.status === 'serving'">
        <div class="counter-bottom-token-info" @aniHeightTransition>
          <i class="counter-origin-type icon-{{ counter.currentlyServing.originType }}"> </i>
          <span class="counter-time">{{
            counter?.currentlyServing?.servingTime | secondsToMinuteSeconds : true | twentyFourToZero
          }}</span>
        </div>
        <span
          *ngIf="counter?.currentlyServing.branchQueueId + '' !== counter.branchQueueId + ''"
          class="counter-different-queue-label"
        >
          * {{ counter.currentlyServing?.branchQueueName }}
        </span>
      </ng-container>
    </div>
    <div class="counter-control">
      <div class="top-bar">
        <button (click)="handleRemoveCounterClick()" class="delete-counter">
          <i class="remove-counter-icon"></i>
        </button>
      </div>

      <div class="button-controls">
        <ng-container *ngIf="counter?.isServing()">
          <button class="noshow-button" (click)="noShow()">
            <i class="fa fa-times" aria-hidden="true"></i>
            <span>No Show</span>
          </button>
          <button class="recall-button" (click)="recall()">
            <i class="fa fa-repeat" aria-hidden="true"></i>
            <span>Recall</span>
          </button>
          <button class="served-button" (click)="markServed()">
            <i class="fa fa-check" aria-hidden="true"></i>
            <span>Served</span>
          </button>
        </ng-container>
        <ng-container *ngIf="!counter?.isServing()">
          <button class="next-button" [disabled]="branchQueue?.totalPeopleInQueue <= 0" (click)="next()">
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
            <span> Next </span>
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<app-modal *ngIf="editing" width="420px" height="auto">
  <div class="counter-console-edit-nickname-modal">
    <h4>
      Edit <b>{{ counter?.counterName }}</b> nickname
    </h4>
    <input
      (keypress)="handleSaveNicknamePress($event)"
      id="edit-nickname-input-{{ counter?.id }}"
      type="text"
      [value]="counter?.nickname"
      [formControl]="nicknameControl"
    />
    <div class="confirmation-buttons">
      <button (click)="handleSaveNicknameClick()">
        Save
        <i class="save-edit-icon"></i>
      </button>
      <button (click)="setEditing(false)">
        Cancel
        <i class="cancel-edit-icon"></i>
      </button>
    </div>
  </div>
</app-modal>
