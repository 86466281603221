<div
  class="counter-display-container"
  [ngStyle]="{ background: customization?.backgroundColor, color: customization?.fontColor }"
  (click)="refreshPage()"
  [@tokenIntvertColorFlashAnimation]="{
    value: tokenAnimationState,
    params: { fontColor: customization?.fontColor, bgColor: customization?.backgroundColor }
  }"
  (@tokenIntvertColorFlashAnimation.done)="tokenAnimationDone()"
>
  <div class="counter-display-content" *ngIf="!showIdleScreen; else noToken">
    <div
      class="counter-name"
      *ngIf="customization?.showCounterName"
      [ngClass]="{ '--with-banner': shouldShowBanner() }"
      [ngStyle]="{
        'font-family': customization?.counterFontFamily,
        fontSize: customization.counterFontSize + 'px',
        backgroundColor: shouldShowBanner() ? customization?.fontColor : '#ffffff00',
        color: shouldShowBanner() ? customization?.backgroundColor : 'inherit'
      }"
    >
      {{ counters[0]?.name }}
    </div>
    <div
      class="token-number"
      [ngClass]="{
        '--without-counter-name': !customization?.showCounterName
      }"
      [ngStyle]="{
        'font-family': customization?.tokenFontFamily
      }"
    >
      {{ latestTokenNumber || '000' }}
    </div>
  </div>
  <ng-template #noToken>
    <div class="idle-container" [ngStyle]="{ background: customization?.backgroundColor }">
      <img src="{{ branchService.details?.logoOriginal }}" alt="" />
    </div>
  </ng-template>
</div>
